import { getConfig } from "../platforms/config";

const config = getConfig();

let BASE_URL = config.api;
if (process.env.NODE_ENV === 'development') {
    //BASE_URL = "https://local.weerlive.com";

    BASE_URL =  "https://api.dev.weerlive.com";
    //BASE_URL = "http://localhost:3001";

}


export default BASE_URL;
