import { EventDTO } from "../features/event/dto/event.dto";

export function getPriceByLocation(event: EventDTO, location: string) : number{
    let price = event.price;

    if (event.priceTable){
        
        for (const newPrice of event.priceTable){
            if (location == newPrice.country){
                price = newPrice.priceWithVAT;
                console.log("price ")
            }
        }
    }
    return price
}

export function getVODPriceByLocation(event: EventDTO, location: string){
    
}