import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import api from '../../common/api'

interface AppState {
    ready: boolean,
    message: string | undefined
    config?: {
      priceMinEvent: number,
      priceMaxEvent: number,
      priceMinReplay: number,
      affiliationDuration: number,
      affiliationPercent: number,
      commission: number,
      eventMaxDuration: number,
      studioAvailableBefore: number
    }
}

const initialState = { 
    ready: false,
    message: undefined,
    config: undefined
 } as AppState


export const getConfig = createAsyncThunk('weerlive/config', async () => {
  const response = await api.get(`weerlive/config`)
  return response.data
});


const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReady(state, action: PayloadAction<boolean>) {
      state.ready = action.payload
    },
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConfig.fulfilled, (state, action) => {
      state.config = action.payload;
    });
  }
})

export const { setReady, setMessage } = appSlice.actions
export default appSlice.reducer