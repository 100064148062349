import { UpdateProfileDTO } from '../dto/update-profile.dto';
import UserDTO from '../dto/user.dto';
import api from '../../../common/api';
import { UpdateCreatorProfileDTO } from '../dto/update-creator-profile.dto';
import { UpdateCreatorSettingsDTO } from '../dto/update-creator-settings.dto';
import logger from '../../../common/logger';

class AuthService {
  async login(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      api.post("auth/login", {
        email,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          this.me().then( (userResponse) => {
            resolve({user: userResponse.data, token: response.data.accessToken});
          }).catch((error) => {
            reject(error);
          });
        }
      }).catch((error) => {
        if (error.response){
          reject(error.response.data.message);
        }else {
          reject(error);

        }
      })
      });
  }
  
  async activate(token: string){
    return api.post(`users/activate/${token}`);
  }

  async me(){
    return api.get("users/me");
  }

  async update(updateProfilDTO: UpdateProfileDTO){
   try{
      return await api.put("users/me", updateProfilDTO);
    }
    catch(e : any){
        logger.error(e.response.data.message);
        throw new Error(e.response.data.message);
      
    }
  }

  async updateCreatorProfil(updateProfilDTO: UpdateCreatorProfileDTO){
    try{
      return await api.put("creator/me", updateProfilDTO);
    }
    catch(e : any){
        logger.error(e.response.data.message);
        throw new Error(e.response.data.message);
      
    }
  }

  async updateCreatorSettings(updateProfilDTO: UpdateCreatorSettingsDTO){
    try{
      return api.put("creator/me/settings", updateProfilDTO);
    }
    catch(e : any){
        logger.error(e.response.data.message);
        throw new Error(e.response.data.message);
      
    }
  }

  async logout() {
    localStorage.removeItem("user");
  }

  async list(){
    return api.get("users");
  }

  async listCreators(){
    return api.get("users/creators");
  }

  async register(country: string, userName: string, email: string, password:string) {
    return api.post("users", {
      country,
      userName,
      email,
      password
    });
  }

  async delete(){
    return api.put("users/me/delete");
  }

  async uploadProfilePicture(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return api.post("users/profile-picture", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  async sendResetPasswordLink(email: string) {
    return api.post("users/reset-password-link", {email});
  }

  async resetPassword(token: string, password: string) {
    return api.post("users/reset-password", {token, password});
  }
  
  async updatePassword(password: string) {
    return api.put("users/me/password", {password});
  }

  async switchCreator(firstName: string, lastName:string, category: string, photo?: File, tiktok?: string, instagram?: string, youtube?: string, twitter?: string) {
    let formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("category", category);
    if (photo){
      formData.append("photo", photo);
    }

    if (tiktok){
      formData.append("tiktok", tiktok);
    }
    if (instagram){
      formData.append("instagram", instagram);
    }
    if (youtube){
      formData.append("youtube", youtube);
    }
    if (twitter){
      formData.append("twitter", twitter);
    }
    return api({
      method: "post",
      url: "creator/me",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

  }

  async registerCreator(country:string, userName: string, email: string, password:string, firstName: string, lastName:string, category: string, photo?: File, partner?: string, tiktok?: string, instagram?: string, youtube?: string, twitter?: string) {

    let formData = new FormData();
    formData.append("country", country);
    formData.append("userName", userName);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("category", category);
    if (photo){
      formData.append("photo", photo);
    }
    if (partner){
      formData.append("partner", partner);
    }
    if (tiktok){
      formData.append("tiktok", tiktok);
    }
    if (instagram){
      formData.append("instagram", instagram);
    }
    if (youtube){
      formData.append("youtube", youtube);
    }
    if (twitter){
      formData.append("twitter", twitter);
    }
    return api({
      method: "post",
      url: "creator",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });


  }

  async validateUser(country: string, userName: string, email: string, password:string) {
    return api.post("users/validate", {
      country,
      userName,
      email,
      password,
    });
  }

  getCurrentUser() : UserDTO | null {
    logger.info('Getting current user from local storage.');
    const user = localStorage.getItem('user');
    if (user){
        logger.debug('User found in local storage.');
        return JSON.parse(user);
    }
    logger.debug('User not found in local storage.');
    return null;
    
  }
}

export default new AuthService();