import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import { getEvent } from "../event-slice";
import EventHeader from "../components/event-header";
import { useNavigation } from "react-router-dom";
import EditEventForm, { EditEventSchema, EditVODEventSchema } from "../components/edit-event-form";
import "./edit-event.css";



export function EditEvent() {
    const { id } = useParams();
    const event = useAppSelector((state) => state.events.event);
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const [editMode, setEditMode] = useState<boolean>(false);


    useEffect(() => {
        if ((id && event && event?._id != id) || id && !event){
            dispatch(getEvent(id));
        }
    }, [id, event]);
    
    const onSubmit = (data: EditEventSchema | EditVODEventSchema) => {
        console.log(data);
        setEditMode(false);
        navigation(`/event/${event?._id}`);

    }

  

    return <>
            { event && <EditEventForm   event={event} onSubmit={ onSubmit }/>} 
            </>

}