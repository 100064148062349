import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import authService from './services/auth-service'
import { UpdateProfileDTO } from './dto/update-profile.dto'
import { UpdateCreatorProfileDTO } from './dto/update-creator-profile.dto'
import { UpdateCreatorSettingsDTO } from './dto/update-creator-settings.dto'
import logger from '../../common/logger'

export interface CompanyDetails {
    companyName: string,
    companyIdNumber: string
    vatOption: number,
    vatNumber?: string,
    owner: string,
    country: string
    address: string,
    postCode: string,
    city: string,
    accountNumber: string,
    swift : string,
    transitNumber?: string,
    institutionNumber?: string,
    bsb?: string,
  
}

export interface User{
    email: string,

    firstName?: string,
    lastName?: string,
    userName: string, 
    picture?: string,
    isAdmin?: boolean,
    createdAt: Date,
    color?: string,
    profileComplete?: boolean,
    profile?: {
      firstName: string,
      lastName: string,      
      category: string,
      picture: string,  
      description?: string,
      phone?: string,
      bankAccount?: string,
      tiktok?: string;
      youtube?: string;
      instagram?: string;
      twitter: string;
      isLive?: boolean;
      companyDetails?: {
        companyName: string,
        companyIdNumber: string
        vatOption: number,
        vatNumber?: string,
        owner: string,
        country: string
        address: string,
        postCode: string,
        city: string,
        accountNumber: string,
        swift : string,
        transitNumber?: string,
        institutionNumber?: string,
        bsb?: string,
      }
    }
    country: string;

    _id: string

}

interface AuthenticatedUser{
    user: User,
    token: string,
}

interface LoginState {
  user?: User,
  token?: string,
  error?: string,
  success?: boolean,
  activated?: boolean,
  users: User[]
}



export const activate = createAsyncThunk('login/activate', async ({id} : {id: string}) => {
  const response = await authService.activate(id)    
  return response
})


export const login = createAsyncThunk('login/login', async ({email, password} : {email: string, password: string}) => {
  logger.info('Try login with email: ' + email)
  const response = await authService.login(email, password)    
  return response
})

export const me = createAsyncThunk('user/me', async ({email, password} : {email: string, password: string}) => {
  const response = await authService.me()
  return response.data
})

export const uploadProfilePicture = createAsyncThunk('user/updatePicture', async (file : File) => {
  const response = await authService.uploadProfilePicture(file)    
  return response.data
})


export const update = createAsyncThunk('user/update', async (user: UpdateProfileDTO) => {
  const response = await authService.update(user)    
  return response.data
})


export const updateCreatorProfil = createAsyncThunk('creator/updateProfil', async (profil: UpdateCreatorProfileDTO) => {
  const response = await authService.updateCreatorProfil(profil)    
  return response.data
})

export const updateCreatorSettings = createAsyncThunk('creator/updateSettings', async (profil: UpdateCreatorSettingsDTO) => {
  const response = await authService.updateCreatorSettings(profil)    
  return response.data
})

export const updatePassword = createAsyncThunk('creator/updatePassword', async (password: string) => {
  const response = await authService.updatePassword(password)    
  return response.data
})

export const deleteUser = createAsyncThunk('user/delete', async () => {
  const response = await authService.delete()    
  return response.data
})

export const list = createAsyncThunk('user/list', async () => {
  const response = await authService.list();
  return response.data
});

export const listCreators = createAsyncThunk('user/creators', async () => {
  const response = await authService.listCreators();
  return response.data
});





const initialState = { user: undefined, users: [] } as LoginState

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<AuthenticatedUser>) {
      state.user = action.payload.user
      state.token = action.payload.token
    },
    cleanMessage(state, action: PayloadAction<undefined>) {
      state.success = undefined
      state.error = undefined
    },
    cleanUser(state, action: PayloadAction<undefined>) {
      state.user = undefined
    },
    setUserData(state, action: PayloadAction<User>) {
      state.user = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action: PayloadAction<AuthenticatedUser>) => {
       logger.info('Login successfull ' + action.payload.user.userName)

        state.token = action.payload.token;
        state.user = action.payload.user;
      });
    builder.addCase(login.rejected, (state, action) => {
      logger.info('Issue with trying to login: ' + action.error.message)

      state.user = undefined;
      state.error = action.error.message;
    });  
    builder.addCase(activate.fulfilled, (state, action: PayloadAction<any>) => {
      state.activated = true;
    });
    builder.addCase(activate.rejected, (state, action) => {
      state.activated = false;
      state.error = action.error.message;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(update.fulfilled, (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.success = true; 
    });
    builder.addCase(updateCreatorProfil.fulfilled, (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.success = true; 
    });
    builder.addCase(updateCreatorProfil.rejected, (state, action) => {
      state.error = action.error.message
      state.success = false; 
    });
    builder.addCase(updateCreatorSettings.fulfilled, (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.success = true;
    });    
    builder.addCase(uploadProfilePicture.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(uploadProfilePicture.fulfilled, (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.success = true; 
    });    
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.success = true;
    });    
    builder.addCase(updateCreatorSettings.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.user = undefined;
    });        
    builder.addCase(list.fulfilled, (state, action) => {
        state.users = action.payload;
    });
    builder.addCase(listCreators.fulfilled, (state, action) => {
      state.users = action.payload;
    })
  }
})

export const {  setUser, cleanMessage, cleanUser, setUserData } = loginSlice.actions
export default loginSlice.reducer