import './studio-bar-button.css';

interface StudioBarButtonProps {
    onClick?: () => void
    backgroundColor?: string
    children?: React.ReactNode
    className?: string
}

export default function StudioBarButton(props: StudioBarButtonProps){
    return <div className={`studio-bar-button ${props.className}`} style={{backgroundColor: props.backgroundColor ? props.backgroundColor : "#272727"}} onClick={ () => {
        props.onClick && props.onClick();
    }}>
        { props.children }
    </div>
}