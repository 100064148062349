import { Col, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import DateFilter, { DateFilterEnum } from "../components/date-filter";
import EventImage from "../../event/components/event-image";
import moment from "moment";
import { useEffect } from "react";
import { listMyEvents } from "../../event/event-slice";
import { getStats } from "../creator-slice";
import { EventState } from "../../event/dto/event.dto";
import "./stats.css"
import { Trans } from "react-i18next";

export default function Stats() {

    
    const user = useAppSelector((state) => state.login.user);
    const stats = useAppSelector((state) => state.creator.stats);
    const currentFilter = useAppSelector((state) => state.creator.currentFilter);
    const events = useAppSelector((state) => state.events.events);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (user){
          dispatch(listMyEvents());
          dispatch(getStats());
        }
      }, [user]);

    return <div>
            <Row className="secondary align-content-center" style={{borderRadius: 20,  padding: 10, textAlign:"center"}}>
                    <Col md={{size: 2}} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.stream"}>Stream</Trans></h2>
                        <h3>{stats?.liveCount}</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.views"}>Views</Trans></h2>
                        <h3>{stats?.views}</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.revenue_live"}>Revenu Live</Trans></h2>
                        <h3>{stats?.revenueLive.toFixed(2)}</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.revenue_replay"}>Revenu Replay</Trans></h2>
                        <h3>{stats?.revenueLive.toFixed(2)}</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.revenue_partner"}>Revenu Affiliation</Trans></h2>
                        <h3>{stats?.revenueReplay.toFixed(2)}</h3>
                    </Col>                    
                    <Col md={2} xs={12}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase',fontWeight: 'bold'}}><Trans i18nKey={"backoffice.creator.stats.revenue_total"}>Revenu Total</Trans></h2>
                        <h3 style={{fontWeight: 'bold'}}>{((stats?.revenueLive || 0) + (stats?.revenueReplay || 0)).toFixed(2)}</h3>
                    </Col>                    
                </Row>    
            

                {/* <DateFilter active={currentFilter} /> */}
                {events.map((event) => {
                return <> { (event.state == EventState.Vod || event.state == EventState.Done) && <Row className="secondary align-content-center stats-contain">
                <Col md={{size: 3}} xs={12} style={{display: "flex"}}>
                    <EventImage image={event.picture} title={event.title} color={event.color}/>
                </Col>
                <Col md={{size: 3}} xs={12} style={{textAlign: "center", marginTop: 10}}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}>{moment(event.date).format('DD/MM/YYYY HH:mm')}</h1>
                    <p>{event.title.substring(0, 65)} {event.title.length > 65 && "..." }</p>
                </Col>
                <Col md={{size: 1}} xs={6} style={{textAlign: "center", marginTop: 10}}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.stats.views"}>Duration</Trans></h1>
                    <p>{ moment.utc(event.duration*1000).format('HH:mm:ss') }</p>
                </Col>                
                <Col md={{size: 1}} style={{textAlign: 'center', marginTop: 10}} xs={6}>
                    <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.stats.views"}>Vues</Trans></h1>
                    <p>{ event.viewerCount }</p>
                </Col>
                {/* <Col md={{size: 2}} style={{textAlign: 'center'}} xs={6}>
                    <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}>Durée vue moyenne</h1>
                    <p>{ event.meanTime || 0 } min</p>
                </Col>        */}
                         
                <Col md={{size: 2}} style={{textAlign: 'center', marginTop: 10}} xs={6}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.stats.revenue_live"}>Gain live</Trans></h1>
                    <p>{ event.revenueLive }</p>
                </Col>      
                <Col md={{size: 2}} style={{textAlign: 'center', marginTop: 10}} xs={6}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.stats.revenue_replay"}>Gain Replay</Trans></h1>
                    <p>{ event.revenueVOD }</p>
                </Col>                                                
            </Row>
            } </>})}
        </div>
}