import UserWidget from "./user-widget";
import { ChatUserItem } from "@zoom/videosdk";
import { ConnectedUser } from "../dto/connected-user.dto";
import BASE_URL from "../../../common/urls";
import '../../../index.scss';
import { ReactElement, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import HeaderWidget from "./header-widget";
import CommunityList from "./community-list";
import { getPlaformAsset, getPlaformImage } from "../../../platforms/config";

const camera = getPlaformImage('studio/camera.svg');
const moderators = getPlaformImage('studio/moderators.svg');
const viewers = getPlaformImage ('studio/viewers.svg');

export interface CommunityWidgetProps{
    users: ConnectedUser[];
    currentUser : ConnectedUser;
    onClickPromote?: Function;
    onClickInvite?: Function;
    onClickAlert?: Function;
    onClickBan?: Function;
}

export default function CommunityWidget(props: CommunityWidgetProps){
    
    const [usersFiltered, setUsersFiltered] = useState<ConnectedUser[]>(props.users);
    const [filter, setFilter] = useState<string>("");

    useEffect( () => {
        if (filter === ""){
            setUsersFiltered(props.users);
        }else {
            setUsersFiltered(props.users.filter( (user) => user.userName.includes(filter)));
        }
    }, [filter])

    useEffect( () => {
        if (filter === ""){
            setUsersFiltered(props.users);
        }else {
            setUsersFiltered(props.users.filter( (user) => user.userName.includes(filter)));
        }
    }, [props.users])


    const userListByRole = (role: number) => {
        let results : ReactElement[] = [];
        {usersFiltered && usersFiltered.map( (user, index) => {
            if (!user.admin && user.role == role || (role == 2 && user.invited)){
                results.push(<UserWidget key={index} 
                                user={user} 
                                me={props.currentUser}
                                onClickAlert={ () => {
                                    if (props.onClickAlert){
                                        props.onClickAlert(user);
                                    }
                                }} 
                                onClickBan={ () => {
                                    if (props.onClickBan){
                                        props.onClickBan(user);
                                    }
                                }} 
                                onClickInvite={ () => {
                                    if (props.onClickInvite){
                                        props.onClickInvite(user);
                                    }
                                }} 
                                onClickPromote={ () => {
                                    if (props.onClickPromote){
                                        props.onClickPromote(user);
                                    }
                                }}                                 
                                />)
            }
        })}
        return results;
    }
    
    return <div className="communitylist-mobile">      
    <div  style={{backgroundColor: "#424242", borderRadius: 20, color: "white", padding: 10, border: "none", marginTop: 20,  display: 'flex',  marginLeft: 5, marginRight: 5}} >
        <img src={camera} style={{marginRight: 10}} />
        <input type="text" placeholder="Filtrer" style={{backgroundColor: "#424242", color: "white", fontWeight: 16, border: 'none', flex: 1, outline: 'none' }} value={filter} onChange={ (ev) => {setFilter(ev.target.value)}}></input>
    </div>   
    <CommunityList name="Broadcaster" icon={camera} color={"red"} users={userListByRole(2)} />
    <hr />
    <CommunityList name="Moderator"  icon={moderators} color={"#7580FC"} users={userListByRole(1)} />
    <hr />
    <CommunityList name="User"  icon={viewers} color={"#05C19E"} users={userListByRole(0)} />

</div> 
}