import { Participant } from "@zoom/videosdk";
import { relative } from "path";
import { useContext, useEffect, useRef, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import Close from "../../../theme/components/close";
import { ConnectedUser } from "../dto/connected-user.dto";
import socketContext from "../context/socket-context";
import useModal from "../../../theme/hooks/use-modal";
import { setUser } from "../../login/login-slice";

interface VideoWidgetProps {  
    mediaStream: any;
    participant: Participant;
    me: ConnectedUser;
    users: ConnectedUser[];
}

declare global {
    namespace JSX {
      interface IntrinsicElements {
        "video-player": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      }
    }
  }

export default function VideoWidget(props: VideoWidgetProps){
    const ref = useRef<HTMLElement>(null);
    const socket = useContext(socketContext);
    const modal = useModal();
    const [userID, setUserID] = useState<number | null>(null);


    useEffect(() => {
        
        if (ref.current){
        
            props.mediaStream.attachVideo(props.participant.userId, 3, ref.current).then( () => {
                console.log("Video attached for "+  props.participant.userId);  
                setUserID(props.participant.userId);
            })

            
        }
        return () => {
            if (ref.current){
                props.mediaStream.detachVideo(userID).then( () => {
                      console.log("User removed " + props.participant.userId + " " + userID);
                }).catch( (error: any) => {
                    console.log(error);
                });
            }

            
        }
    }, [ref.current]);


    const socketUser = props.users.find( (user) => user.userName == props.participant.displayName  )

    return !props.participant.bVideoOn ? <div className="no-video-user">
        <h1>{props.participant.displayName}</h1>
    </div> : <div className="video-user-container" style={{display: "flex", position: 'relative', flex: 1}}>


                <video-player className="video-user" style={{flex: 1, position: "relative"}} ref={ref} />

                  
                <div className="overlay-video">
                    <div className="overlay-video-content">
                            <div style={{position: "absolute", top: 10, left: 10, textAlign: "center", background: "rgba(19,16,21, 0.8)", fontSize: 12, borderRadius: 20, paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, marginRight: 10}}>
                                {props.participant.displayName}  
                            </div>
                        { props.me.role == 2 && props.participant.displayName != props.me.userName && <>
  
                                <div style={{position: "absolute", right: 50, top : 10, backgroundColor: "rgba(19,16,21, 0.8)", borderRadius: 20, paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, marginRight: 10, alignContent: "center"}}>
                                    <FormGroup switch style={{alignItems: "center", justifyItems: "center", justifyContent: "center", flexDirection: "row"}}>
                                        <label htmlFor="show-on-stream">Show on stream</label>
                                        <Input checked={socketUser ? socketUser.visible : false } type="switch" id="show-on-stream" onChange={ (event) => {
                                            socket.emit("user-visible", {socketID: socketUser?.socketID})
                                        }} />
                                    </FormGroup>
                                </div>

                        <div style={{position: "absolute", top:5, right:5}}>
                        <Close onClick={ () => {
                            const user = props.users.find( (user) => user.userName == props.participant.displayName  )
                            modal.show("Étes-vous sur de vouloir retirer l'utilisateur du live ?", <>Étes-vous sur de vouloir retirer l'utilisateur du live ?</>, [{
                                    label: "Oui",
                                    onClick : () => {
                                        if (user){
                                            socket.emit("user-invite", {socketID: user.socketID});
                                        }
                                    }
                                },
                                {
                                    label: "Non",
                                    onClick : () => {

                                    }
                                }
                            ]);
        
                        } }/>
                        </div>
                    </>}
                    </div>
                </div>

      </div>
}