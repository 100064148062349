import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Page } from "../../../theme/templates/page";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import EventImage from "../../event/components/event-image";
import { EventCard } from "../../event/components/event-card";
import { listAllEvents, listEventByCategory, listNextEventByCategory } from "../../event/event-slice";
import { Trans } from "react-i18next";
import "./home.css";
import { Link as ScrollLink , animateScroll as scroll } from "react-scroll";
import { getPlaformImage } from "../../../platforms/config";
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from "../../../theme/components/loading";


const homeImage = getPlaformImage("home-viewers.png");

export default function Live() {
    const navigation = useNavigate();
    const user = useAppSelector((state) => state.login.user);
    const ready = useAppSelector((state) => state.app.ready);
    const events = useAppSelector((state) => state.events.events);
    const count = useAppSelector((state) => state.events.eventCount);
    const next = useAppSelector((state) => state.events.eventNext);
    const loading = useAppSelector((state) => state.events.loading);

    const dispatch = useAppDispatch();
    const categories = useAppSelector( (state) => state.events.categories) 

    const {category, filter} = useParams();

    useEffect( () => {
        console.log(category);
        if (!category && !filter){
            dispatch(listEventByCategory({category: "all", filter: "live"}))
        }if (category){
            dispatch(listEventByCategory({category, filter}))
        }
    }, [category, filter]);

    return <Page className="home landing">
        <Container>
            <Row>
                <Col className="text-center">
                    <h2 id="discover-lives" className="discover-lives"><Trans i18nKey="home.user.header.discover_lives_title">Découvrir les lives !</Trans></h2>
                </Col>
            </Row> 
            <Row className="tags-row">
                <Col md={12} className="tag-container">
                            <Link preventScrollReset={true} to={`/live/all`}><Button color={"green"} className={`tag ${(category != "all" && category != undefined) ? 'tag-disabled' : ''}`}>All</Button></Link>
                            {categories.map( (value, index) => {
                                const colors = ["orange", "yellow", "purple", "blue"];
                                return <Link preventScrollReset={true} to={`/live/${value._id}`}><Button className={`tag ${category != value._id ? 'tag-disabled' : ''}`} color={colors[index % colors.length]}>{value.name}</Button></Link>
                            })}
                </Col>
            </Row>    
            <Row className="live-selector-row">
                <Col>
                       <Link preventScrollReset={true} to={`/live/${category ===undefined ? "all" : category}/live`} className={`selector ${filter && filter !=="live" ? 'selector-disabled' : ''}` } style={{textDecoration: !filter || filter == "live" ? "underline" : "none" }}><Trans i18nKey="home.user.in_live">Live en cours</Trans></Link>
                       <Link preventScrollReset={true} to={`/live/${category  === undefined ? "all" : category}/scheduled`} className={`selector ${!filter || filter !=="scheduled" ? 'selector-disabled' : ''}`} style={{textDecoration: filter && filter == "scheduled" ? "underline" : "none" }}><Trans i18nKey="home.user.live.schechuled">Live Programmés</Trans></Link>
                       <Link preventScrollReset={true} to={`/live/${category === undefined ? "all" : category}/replay`} className={`selector ${!filter || filter !=="replay" ? 'selector-disabled' : ''}`} style={{textDecoration: filter && filter == "replay" ? "underline" : "none" }}><Trans i18nKey="home.user.live.replay">Replays</Trans></Link>
                </Col>
            </Row>      
            { loading ? <Loading />: <InfiniteScroll hasMore={next != null} loader={<div style={{display: "flex", flex: 1, justifyContent:"center"}}><Spinner color="light"/></div>} dataLength={count ? count : 0} next={ () => {
                    if (!category && !filter){
                        dispatch(listNextEventByCategory({category, filter, offset: next }))
                    }if (category){
                            dispatch(listNextEventByCategory({category, filter, offset: next}))
                        }


                }}>
                <Row className="live-row">
                {events.map((event) => (
                        <Col md={6} lg={6} xl={4} className="live-col">
                            <Link to={`/event/${event._id}`} style={{textDecoration: "none"}}>
                                <EventCard event={event} />
                            </Link>
                        </Col>
                ))}  
                </Row>   

                </InfiniteScroll> }
            <Row className="creator-row">
                <Col md={8} className="creator">
                    <h2><Trans i18nKey="home.creator.footer.creator_title">Devenez Créateurs</Trans></h2>
                    <p><Trans i18nKey="home.creator.footer.creator_tagline">Plongez dans le monde du streaming live où votre créativité rencontre la monétisation. Partagez votre passion tout en monétisant votre contenu.</Trans></p>
                    <Link to={"/creator"} className="creator-cta"><Button color="primary"><Trans i18nKey="home.creator.footer.call_to_action">Devenez créateur</Trans></Button></Link>
                    <img style={{justifySelf:"stretch"}} src={getPlaformImage('home.png')}/>
                </Col>
            </Row>                             
                                          
        </Container>
    </Page>
  }   