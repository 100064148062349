import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import "./profil.css";
import { EventCard } from "../../event/components/event-card";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { listEventsByCreator } from "../../event/event-slice";
import { Link, useParams } from "react-router-dom";
import CreatorImage from "../components/creator-image";
import CreatorHeader from "../components/creator-header";
import { getCreatorByUserName } from "../creator-slice";
import { EventDTO, EventState } from "../../event/dto/event.dto";
import EventHeader from "../../event/components/event-header";
import { Trans } from "react-i18next";

export default function Profil() {
  const creator = useAppSelector((state) => state.creator.creator);
  const loading = useAppSelector((state) => state.events.loading);
  const events = useAppSelector((state) => state.events.events);
  const dispatch = useAppDispatch();
  let { id } = useParams();

  const [eventsLive, setEventsLive] = useState<EventDTO[]>([]);
  const [eventsScheduled, setEventsScheduled] = useState<EventDTO[]>([]);
  const [eventsVOD, setEventsVOD] = useState<EventDTO[]>([]);

  useEffect(() => {
    if (id !== undefined){
      dispatch(getCreatorByUserName(id));
    }
  }, [id]);

  useEffect(() => {
    
    setEventsLive(events.filter((event) => event.state == EventState.Live));
    setEventsScheduled(events.filter((event) => event.state == EventState.Draft));
    setEventsVOD(events.filter((event) => event.state == EventState.Vod));
    
  }, [events]);

  useEffect(() => {
    if (creator !== null && creator !== undefined){
      dispatch(listEventsByCreator(creator._id));
    }
  }, [id, creator]);

  
  return <>
  {creator && <><CreatorHeader creator={{...creator.profile, userName: creator.userName, picture: creator.picture, color: creator.color}} />
  { loading ? <div style={{display: "flex", flex: 1, justifyContent:"center"}}><Spinner color="light"/></div> :<>

  { eventsLive.length > 0 && <><Row style={{marginBottom: 20}}>
                <Col>
                  <h2 style={{color: 'white', fontWeight: "bold", fontStyle: 'normal'}}><Trans i18nKey={"profil.live"}>Live</Trans></h2>
                </Col>
  </Row> 
  <Row>
    {eventsLive.map((event) => {
        return <>{event.state == EventState.Live  && 
          <Link to={`/event/${event._id}`} style={{textDecoration: "none"}}>
            <EventHeader event={event} />
          </Link>
       }</>
      })}
  </Row> </>}
  <Row style={{marginBottom: 20}}>
                <Col>
                  <h2 style={{color: 'white', fontWeight: "bold", fontStyle: 'normal'}}><Trans i18nKey={"profil.live_scheduled"}>Lives programmés</Trans></h2>
                </Col>
  </Row> 
  <Row>
    {eventsScheduled.map((event) => {
        return <>{event.state == EventState.Draft  && <Col md={4}>
          <Link to={`/event/${event._id}`} style={{textDecoration: "none"}}>
            <EventCard event={event} />
          </Link>
        </Col>}</>
      })}
  </Row>    
  <Row style={{marginBottom: 20}}>
                <Col>
                  <h2 style={{color: 'white', fontWeight: "bold", fontStyle: 'normal'}}><Trans  i18nKey={"profil.live_scheduled"}>Lives programmés</Trans></h2>
                </Col>
  </Row>       
  <Row>
      {eventsVOD.map((event) => {
        return <>{event.state == EventState.Vod  && <Col md={4}>
          <Link to={`/event/${event._id}`} style={{textDecoration: "none"}}>
            <EventCard event={event} />
          </Link>
        </Col>}</>
      })}
  </Row>    
  </>}
  </>}
  </>
}