import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { listSubscribedEvents } from "../../event/event-slice";
import { EventState } from "../../event/dto/event.dto";
import { EventCard } from "../../event/components/event-card";
import { Trans } from "react-i18next";

export function Overview() {
    const dispatch = useAppDispatch();
    const events = useAppSelector(state => state.events.events);
    useEffect( () => {
        dispatch(listSubscribedEvents());
    }, [])
    return (
    <div>
      <h1>Overview</h1>
      <Row style={{marginTop: 10}}>
            <Col md={12} xl={7} >
                <Row className="secondary align-content-center" style={{borderRadius: 20, height: 120, padding: 10}}>
                    Bienvenue ! 
                </Row>                
            </Col>
            <Col className="secondary align-content-center text-center" style={{borderRadius: 20, padding: 20, height: 120}} xl={{
                offset: 1,
                size: 4
            }}>
                <Link to="/">
                    <Button color="primary"><Trans i18nKey="backoffice.user.overview.lives">Voir les lives</Trans></Button>
                </Link>
            </Col>
        </Row>

        <h1 style={{fontSize: 22, marginTop: 40}}><Trans i18nKey="backoffice.user.overview.lives_in_progress">Lives en cours</Trans></h1>
        <Row>
            {events.map((event) => {
                if (event.state === EventState.Live){
                    return  <Col md={12} lg={6}>
                        <NavLink to={`/event/${event._id}`} style={{textDecoration: "none"}}><EventCard key={event._id} event={event} /></NavLink>
                    </Col>
                }else {
                    return <></>
                }
 
            })}               
          </Row>   
        <h1 style={{fontSize: 22, marginTop: 40}}><Trans i18nKey="backoffice.user.overview.live_scheduled">Lives programmés</Trans></h1>
        <Row>
            {events.map((event) => {
                if (event.state === EventState.Draft){
                    return  <Col md={12} lg={6}>
                        <NavLink to={`/event/${event._id}`} style={{textDecoration: "none"}}><EventCard key={event._id} event={event} /></NavLink>
                    </Col>
                }else {
                    return <></>
                }
 
            })}               
          </Row>   
        <h1 style={{fontSize: 22, marginTop: 40}}><Trans i18nKey="backoffice.user.overview.replay">Replays disponibles</Trans></h1>
        <Row>
            {events.map((event) => {
                if (event.state === EventState.Vod){
                    return  <Col md={12} lg={6}>
                        <NavLink to={`/event/${event._id}`} style={{textDecoration: "none"}}><EventCard key={event._id} event={event} /></NavLink>
                    </Col>
                }else {
                    return <></>
                }
 
            })}               
          </Row>  
    </div>
  );
}