import { useParams } from "react-router-dom";
import { Alert, Button, Form } from "reactstrap";
import {Elements, PaymentElement} from '@stripe/react-stripe-js';
import {Stripe, loadStripe} from '@stripe/stripe-js';
import { useEffect, useState } from "react";
import api  from "../../../common/api";
import EventHeader from "../components/event-header";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getEvent } from "../event-slice";
import { Col, Row } from "reactstrap"
import { CheckoutForm } from "../components/checkout-form";
import { EventPaymentType, EventState } from "../dto/event.dto";

export function CheckoutEvent() {
    const { id } = useParams();
    const event = useAppSelector((state) => state.events.event);
    const [ stripePromise, setStripePromise ] = useState<null | Promise<Stripe | null>>(null);
    const [ clientSecret, setClientSecret ] = useState('');
    const dispatch = useAppDispatch();
    const [message, setMessage] = useState<string | undefined>('')

    useEffect(() => {
      if (id && !event){
        dispatch(getEvent(id));
      }
    }, [id]);

    useEffect(() => {
      if (id){
        api.post(`events/${id}/checkout`)
        .then((res) => res.data)
        .then(({clientSecret}) => setClientSecret(clientSecret))
        .catch((e) => setMessage(e.response.data.message));
      }
     
    }, [id]);
  
  

    useEffect(() => {
      api("/payment/public-key").then(async (r) => {
        const { publishableKey } = await r.data;
        setStripePromise(loadStripe(publishableKey));
      });
    }, []);

    return  <>{event && <>
        { message && <Alert color="danger">{message}</Alert> }
        <Row>
          
          {clientSecret != '' && <Elements stripe={stripePromise} options={{clientSecret: clientSecret, appearance: {theme: 'stripe',   variables: {
                colorPrimary: '#424242',
                colorBackground: '#272727',
                colorText: '#D9D9D9',
                colorDanger: '#df1b41',
                fontFamily: 'Ideal Sans, system-ui, sans-serif',
                spacingUnit: '5px',
                borderRadius: '10px',

                
              }} 
            }}>
              <div style={{  padding: 30, borderRadius: 50}}>
                <CheckoutForm successUrl={`event/${id}/checkout/success`} mode={(event.state != EventState.Vod && event.paymentType == EventPaymentType.Time) ? "setup" : "payment"} />
            </div>
          </Elements>}
        </Row>
    </> }</>

}