import React, { useEffect, useMemo, useState } from "react";
import { Col, FormGroup, Row, Label, Input, Button, Form, Alert, FormFeedback, InputGroup, InputGroupText } from "reactstrap";
import FormInput from "../../../theme/components/form-input";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector, useYupValidationResolver } from "../../../hooks";
import * as yup from "yup"
import { cleanMessage, update, updateCreatorProfil, uploadProfilePicture } from "../../login/login-slice";
import CreatorImage from "../components/creator-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import countryList from "react-select-country-list";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

import { INSTAGRAM_PREFIX, TIKTOK_PREFIX, TWITTER_PREFIX, YOUTUBE_PREFIX } from "../../../common/social";
import ImageCropperModal from "../../../theme/components/image-cropper-modal";

type EditProfilFormValues = {
    userName: string,
    category: string,
    description?: string
    country: string,
    tiktok?: string,
    youtube?: string,
    instagram?: string,
    twitter?: string
}


const EditProfilValidationSchema = yup.object({
  userName: yup.string().min(2).required(),
  description: yup.string().min(0).notRequired(),
  category: yup.string().min(2).required(),
  country: yup.string().min(2).required(),
  twitter: yup.string().notRequired(),
  youtube: yup.string().notRequired(),
  instagram: yup.string().notRequired(),
  tiktok: yup.string().notRequired()
})


export default function EditProfil(){
    const resolver = useYupValidationResolver(EditProfilValidationSchema)
    const user = useAppSelector((state) => state.login.user);
    const categories = useAppSelector((state) => state.events.categories);
    const countries = useMemo(() => countryList().getData(), [])

    const { register: _register, handleSubmit, formState: { errors } } = useForm<EditProfilFormValues>({resolver, defaultValues: {userName: user?.userName, category: user?.profile?.category, description: user?.profile?.description, tiktok: user?.profile?.tiktok, youtube: user?.profile?.youtube, instagram: user?.profile?.instagram, twitter: user?.profile?.twitter, country: user?.country}})
    const dispatch = useAppDispatch();
    const onSubmit: SubmitHandler<EditProfilFormValues> = (data) => {
        console.log(data);
        dispatch(updateCreatorProfil({userName : data.userName, description: data.description, category: data.category, country: data.country, twitter: data.twitter, youtube: data.youtube, instagram: data.instagram, tiktok: data.tiktok}));
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file){
        setCropperThumbnailURL(URL.createObjectURL(file));
        setShowEditor(true);
      }
    }

    const [showEditor, setShowEditor] = useState(false);
    const [cropperThumbnailURL, setCropperThumbnailURL] = useState<String>("");
  
    const onCrop = (blob: Blob) => {
      let file = new File([blob], "file.png", {type:"image/png"});
      dispatch(uploadProfilePicture(file));
      setShowEditor(false);
    }

    const success = useAppSelector((state) => state.login.success);
    const error = useAppSelector((state) => state.login.error);

    const fileInput = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (success || error){
        setTimeout(() => {
          dispatch(cleanMessage());
        }, 3000)
      }
    }, [success, error]);


    return <>
              { success && <Alert color="success">
              Paramètres modifiés avec succès
            </Alert> }
            { error && <Alert color="danger">
              Erreur lors de la modification des paramètres : {error}
            </Alert> }
          { user && <Form method="post" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="formLastName">
                    <Trans i18nKey={"form.user_name"}>Pseudo</Trans>
                  </Label>
                  <FormInput
                    id="formPseudo"
                    placeholder={t("form.user_name")}
                    register={_register}
                    name="userName" />
                    <FormFeedback>
                      { errors.userName?.message }
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="formLastName">
                    <Trans i18nKey="form.description">Description</Trans>
                  </Label>
                  <FormInput
                    id="formDescription"
                    type="textarea"
                    placeholder={t("form.description")}
                    register={_register}
                    style={{height: '210px'}}
                    name="description" />
                    <FormFeedback>
                      { errors.description?.message }
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="formLastName">
                    <Trans i18nKey={"form.activity"}>
                      Secteur d'activité
                    </Trans>
                  </Label>
                  <FormInput
                    id="formActivity"
                    type="select"
                    placeholder={t("form.activity")}
                    register={_register}
                    name="category">
                      {categories.map((category) => {
                        return <option value={category._id}>{category.name}</option>
                      })};
                  </FormInput>
                  <FormFeedback>
                      { errors.category?.message }
                    </FormFeedback>
                </FormGroup>

                <FormGroup>
                <Label for="formCountry">
                    <Trans i18nKey={"form.country"}></Trans>
                  </Label>
                    <FormInput
                      id="formCountry"
                      placeholder={t("form.country")}
                      type="select"
                      register={_register}
                      name="country"
                      invalid={errors.country}
                      style={{backgroundColor: "white"}}
                      defaultValue="FR"
                    >
                      { countries.map((country) => {
                        return <option key={country.value} value={country.value}>{country.label}</option>
                      })}
                    </FormInput>
                    <FormFeedback>
                      { errors.country?.message }
                    </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="formFirstName">
                    <Trans i18nKey={"form.profile_picture"}>Photo de profil</Trans>
                  </Label>
                  <div className="profileContainer" style={{display: "flex", flexDirection: 'column', justifyContent: 'center', width: "50%", margin: "20px auto"}}>
                    <CreatorImage userName={user!.userName} image={user!.picture} color={user!.color} />
                    <input ref={fileInput} style={{display: "none"}} type="file" onChange={ onChange }/>
                    <p style={{color:'#8A8A8A', width: '100%', textAlign: 'center', marginTop: 10}}><FontAwesomeIcon icon={faTriangleExclamation} /> Recommanded size : 500x500 (1:1)</p>
                    <Button color="primary" style={{marginLeft: 20}} onClick={ () => {
                      fileInput.current?.click()
                    }}><Trans i18nKey={"form.change"}>Changer</Trans></Button>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
              <FormGroup>
                <Label for="formTwitter">
                      Twitter
                    </Label>
                    <InputGroup>
                    <InputGroupText>{TWITTER_PREFIX}</InputGroupText>
                    <FormInput
                      id="formTwitter"
                      type="text"
                      placeholder="Twitter"
                      register={_register}
                      name="twitter"
                      invalid={errors.twitter}>
                    </FormInput>
                    </InputGroup>
                    <FormFeedback>
                      { errors.twitter?.message }
                   </FormFeedback>
                  </FormGroup>

              </Col>
              <Col md={6}>
              <FormGroup>
                <Label for="formYoutube">
                      Youtube
                    </Label>
                    <InputGroup>
                      <InputGroupText>{YOUTUBE_PREFIX}</InputGroupText>
                      <FormInput
                        id="formYoutube"
                        type="text"
                        placeholder="Youtube"
                        register={_register}
                        name="youtube"
                        invalid={errors.youtube}>
                      </FormInput>
                    </InputGroup>
                    <FormFeedback>
                      { errors.youtube?.message }
                    </FormFeedback>
                  </FormGroup>

              </Col>
            </Row>
            <Row>
              <Col md={6}>
              <FormGroup>
                  <Label for="formInstagram">
                    Instagram
                  </Label>
                  <InputGroup>
                      <InputGroupText>{INSTAGRAM_PREFIX}</InputGroupText>
                  <FormInput
                    id="formInstagram"
                    type="text"
                    placeholder="Instagram"
                    register={_register}
                    name="instagram"
                    invalid={errors.instagram}>

                  </FormInput>
                  </InputGroup>
                  <FormFeedback>
                      { errors.instagram?.message }
                    </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
              <FormGroup>
                  <Label for="formTiktok">
                    Tiktok
                  </Label>
                  <InputGroup>
                      <InputGroupText>{TIKTOK_PREFIX}</InputGroupText>
                  <FormInput
                    id="formTiktok"
                    type="text"
                    placeholder="Tiktok"
                    register={_register}
                    name="tiktok"
                    invalid={errors.tiktok}>
                  </FormInput>
                  </InputGroup>
                  <FormFeedback>
                      { errors.tiktok?.message }
                    </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <ImageCropperModal onCancel={() => {
              setShowEditor(false);
            }} visible={showEditor} onCrop={(blob: Blob) => {
              onCrop(blob);
            }} image={cropperThumbnailURL} aspectRatio={1} />

            <Button type="submit" color="primary">
              <Trans i18nKey={"form.save"}>Sauvegarder</Trans>
            </Button>
        </Form>}
    </>
}
