import { User } from "../../login/login-slice";

export enum EventState{
    Draft = 'draft', 
    Published = 'published',
    Live = 'live', 
    Done = 'done', 
    Vod = 'vod',
    Archived = 'archived',
    Canceled = 'canceled'
}

export enum EventPaymentType{
    Event = 0,
    Time = 1
}

export interface PriceTable{
    country: string,
    priceWithVAT: number,
    priceWithoutVAT: number,
    vatPercent: number,
    vatAmount: number
}

export interface EventDTO{
    _id: string,
    title: string,
    description: string,
    picture?: string,
    category: string,
    date: Date,
    chatEnabled: boolean,
    state: EventState,
    price: number,
    paymentType: EventPaymentType,
    isOwner?: boolean,
    isSubscribed?: boolean,
    liveStartedAt?: Date,
    liveEndedAt?: Date,
    isPublic: boolean,
    priceVOD: number,
    vodURL: string,
    viewerCount? : number;
    revenueLive?: number;
    revenueVOD?: number;
    seats?: number;
    isFull?: boolean;
    subscribers?: User[];
    subscribersVod?: User[];
    color?: string;
    priceTable?: PriceTable[];
    priceVODTable?: PriceTable[];
    owner: {
        _id: string,
        userName: string,
        email: string,
        picture: string,
        color?: string,
        profile:{
            description: string,
            isLive?: boolean,

        }
    }
}