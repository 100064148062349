import { Container } from "reactstrap";

import { Trans } from 'react-i18next'
import i18n from '../../i18n';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTiktok, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import "./footer.css"
import { getPlaformImage } from "../../platforms/config";
import { Link } from "react-router-dom";

const logo = getPlaformImage("logo.svg");
const logoMobile = getPlaformImage("logo-notext.svg");



export function Footer(){

    
    return <div className="mt-auto footer">
        <Container className="footer-container">
            <div className="footer-content-logo">
                    <img className="logo-desktop" src={logo} style={{marginRight: 50}} />
                    <img className="logo-mobile" src={logoMobile} style={{marginRight: 50}} />

                    <div style={{fontSize: '22px', display: 'inline-block'}}>
                        <a style={{marginRight: '15px'}} href=""><FontAwesomeIcon icon={faInstagram} /></a><a style={{marginRight: '15px'}} href=""><FontAwesomeIcon icon={faTiktok} /></a><a href=""><FontAwesomeIcon icon={faXTwitter} /></a>
                </div>
            </div>
            <div className="footer-content-language">
                    <select className="form-select footer-content-language-select" id="switch_i18n" value={i18n.language} onChange={(ev) => {
                        i18n.changeLanguage(ev.target.value).then(() => {
                            window.location.reload();
                        });
                        }}>
                        <option value="en"><Trans i18nKey={"common.lang.english"}>English</Trans></option>
                        <option value="fr"><Trans i18nKey={"common.lang.french"}>French</Trans></option>
                    </select>
                </div>            
            <div className="footer-links">
               <Link to="/contact"><Trans i18nKey={"footer.contact"}>Contact</Trans></Link>  |    <Link to="/faq"><Trans i18nKey={"footer.faq"}>FAQ</Trans></Link> | <Link to="/legal"><Trans i18nKey={"footer.legal"}>Mentions légales</Trans></Link>     |    <Link to="/terms"><Trans i18nKey={"footer.terms"}>Conditions générales de vente</Trans></Link>
            </div>    

        </Container>
    </div>
}