import { Link, Navigate, ScrollRestoration, createBrowserRouter, useParams } from "react-router-dom";
import Login from "./features/login/pages/login";
import RevenueSimulator from "./features/marketing/pages/revenue-simulator";
import { LiveEvent } from "./features/event/pages/live-event";
import CreateEvent from "./features/event/pages/create-event";
import Profil from "./features/creator/pages/profil";
import { Page } from "./theme/templates/page";
import { Dashboard } from "./theme/templates/dashboard";
import { Overview } from "./features/creator/pages/overview";
import Logout from "./features/login/pages/logout";
import Home from "./features/home/pages/Home";
import CreatorRequired from "./features/creator/components/creator-required";
import EditProfil from "./features/creator/pages/edit-profil";
import { EditEvent } from "./features/event/pages/edit-event";
import ShowEvent from "./features/event/pages/show-event";
import { CheckoutEvent } from "./features/event/pages/checkout-event";
import { CheckoutEventSuccess } from "./features/event/pages/checkout-event-complete";
import Activate from "./features/login/pages/activate";
import { Funnel } from "./theme/templates/funnel";
import LoginLeftPane from "./features/creator/components/login-left-panel";
import Validation from "./features/login/pages/validation";
import CheckoutLeftPane from "./features/event/components/checkout-left-pane";
import { Partners } from "./features/creator/pages/partners";
import Studio from "./features/studio/Studio";
import { Partner } from "./features/creator/pages/partner";
import CreateViewer from "./features/viewer/pages/create-viewer";
import CreateCreatorProfil from "./features/creator/pages/create-profil";
import Settings from "./features/creator/pages/settings";
import Revenues from "./features/creator/pages/revenues";
import Stats from "./features/creator/pages/stats";

import  ViewerProfile from "./features/viewer/pages/profile";

import { Overview as ViewerOverview } from "./features/viewer/pages/overview";

import ViewerRequired from "./features/viewer/components/viewer-required";
import ForgotPassword from "./features/login/pages/forgot-password";
import ForgotPasswordSuccess from "./features/login/pages/forgot-password-success";
import ResetPassword from "./features/login/pages/reset-password";
import ResetPasswordSuccess from "./features/login/pages/reset-password-success";
import Landing from "./features/creator/pages/landing";
import Invoices from "./features/viewer/pages/invoices";
import VOD from "./features/event/pages/play";
import AdminHome from "./features/admin/pages/admin-home";
import AdminRequired from "./features/admin/components/admin-required";
import AdminUsers from "./features/admin/pages/admin-users";
import AdminCreators from "./features/admin/pages/admin-creators";
import AdminEvents from "./features/admin/pages/admin-events";
import Demo from "./theme/demo";
import SwitchCreatorProfil from "./features/creator/pages/switch-creator";
import AdminPayments from "./features/admin/pages/admin-payments";
import AdminEvent from "./features/admin/pages/admin-event";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileVideo, faVideo } from "@fortawesome/free-solid-svg-icons";
import { faViadeo } from "@fortawesome/free-brands-svg-icons";
import Root from "./Root";
import Live from "./features/home/pages/live";
import { Trans } from "react-i18next";
import Legal from "./features/home/pages/legal";
import Terms from "./features/home/pages/terms";
import Faq from "./features/home/pages/faq";
import Contact from "./features/home/pages/contact";
import { t } from "i18next";
import Redirect from "./theme/components/redirect";
import path from "path";


const routes = [
  {
    path: '/?:lang(fr)?',
    element: <Root /> ,
    children:[
      {
        path: "",
        element: <Home />
      },
      {
        path: "live",
        element: <Live />
      },
      {
        path: "legal",
        element: <Legal />
      },    
      {
        path: "terms",
        element: <Terms />
      },        
      {
        path: "contact",
        element: <Contact />
      },              
      {
        path: "faq",
        element: <Faq />
      },                  
      {
      path: "live/:category",
      element: <Live />
    },
    {
      path: "partner/:partner",
      element: <Partner />
    },
    {
      path: "p/:id",
      element: <Redirect to="/partner" />
    },    
    {
      path: "live/:category/:filter",
      element: <Live />
    },        
    {
      path: "logout",
      element: <Logout />,
    },
    {
      path: "reset-password-success",
      element: <Funnel title="Reset your password successfully" left={<LoginLeftPane />}><ResetPasswordSuccess /></Funnel>,
    }, 
    {
      path: "reset-password/:token",
      element: <Funnel title="Reset your password" left={<LoginLeftPane />}><ResetPassword /></Funnel>,
    }, 
    {
      path: "forgot-password",
      element: <Funnel title="Reset password" left={<LoginLeftPane />}><ForgotPassword /></Funnel>,
    }, 
    {
      path: "forgot-password-success",
      element: <Funnel title="" left={<LoginLeftPane />}><ForgotPasswordSuccess /></Funnel>,
    },     
    {
      path: "login",
      element: <Funnel title="Login" left={<LoginLeftPane />}><Login /></Funnel>,
    },      
    {
      path: "validation",
      element: <Funnel title="Congratulations!" left={<LoginLeftPane />}><Validation /></Funnel>,
    },          
    {
      path: "activate/:id",
      element: <Activate />,
    },     
    {
      path: "live",
      element: <LiveEvent />,
    },     
    {
      path: "revenue-simulator",
      element: <RevenueSimulator />,
    },        
    {
      path: "creator/create-profil",
      element: <Funnel title="Create Profil" left={<LoginLeftPane />}><CreateCreatorProfil /></Funnel>,
    },  
    {
      path: "creator/switch-creator",
      element: <Funnel title="Create Profil" left={<LoginLeftPane />}><SwitchCreatorProfil /></Funnel>,
    },    
    {
      path: "viewer/create-profil",
      element: <Funnel title="Create Profil" left={<LoginLeftPane />}><CreateViewer /></Funnel>,
    },        
    {
      path: "profil/:id",
      element: <Page><Profil /></Page>,
    },            
    {
      path: "event/:id",
      element: <Page  className="showEvent"><ShowEvent /></Page>,
    },
    {
      path: "e/:id",
      element: <Redirect to="/event" />
    },
    {
      path: "event/:id/edit",
      element: <CreatorRequired><Page className="editEvent"><EditEvent /></Page></CreatorRequired>,
    },                      
    {
      path: "event/:id/checkout",
      element: <ViewerRequired><Funnel title="Checkout" left={<CheckoutLeftPane />}><CheckoutEvent /></Funnel></ViewerRequired>,
    },   
    {
      path: "event/:id/checkout/success",
      element: <Funnel><CheckoutEventSuccess /></Funnel>,
    },       
    {
      path: "vod/:id",
      element: <Page><VOD /></Page>,
    },                     
    {
      path: "create-profil",
      element: <Funnel title="Create Profil" left={<LoginLeftPane />}>
          
          <Link to={"/viewer/create-profil"}><Row>
                      <Col style={{height: 100, display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center", textAlign: "center", border: "1px solid #424242", margin: 10, borderRadius: 20, marginTop: "50px", fontWeight: 600}}><FontAwesomeIcon style={{marginRight: '10px'}} icon={faEye} /><Trans i18nKey="signup.as_viewer">Devenir viewer</Trans></Col>
                </Row>
          </Link>
          <Link to={"/creator/create-profil"}><Row>
                  <Col style={{height: 100, display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center", textAlign: "center", border: "1px solid #424242", margin: 10,  borderRadius: 20, fontWeight: 600}}><FontAwesomeIcon style={{marginRight: '10px'}} icon={faVideo} /><Trans i18nKey="signup.as_creator">Devenir créateur</Trans></Col>
              </Row>
          </Link>

      </Funnel>,
    },                     
        
    {
      path: "admin",
      children: [
        {
          index: true,
          path: "",
          element: <AdminRequired><Dashboard title="Admin"><AdminHome/></Dashboard></AdminRequired>
        },                     
        {
          index: true,
          path: "users",
          element: <AdminRequired><Dashboard title="Admin"><AdminUsers/></Dashboard></AdminRequired>
        },   
        {
          index: true,
          path: "creators",
          element: <AdminRequired><Dashboard title="Admin"><AdminCreators/></Dashboard></AdminRequired>
        },   
        {
          index: true,
          path: "event/:id",
          element: <AdminRequired><Dashboard title="Admin"><AdminEvent/></Dashboard></AdminRequired>
        },   
        {
          index: true,
          path: "events",
          element: <AdminRequired><Dashboard title="Admin"><AdminEvents/></Dashboard></AdminRequired>
        },         
        {
          index: true,
          path: "events/:category/:filter",
          element: <AdminRequired><Dashboard title="Admin"><AdminEvents/></Dashboard></AdminRequired>
        },         
        {
          index: true,
          path: "payments",
          element: <AdminRequired><Dashboard title="Admin"><AdminPayments/></Dashboard></AdminRequired>
        },                           
        
      ]
    },
    {
      path: "viewer",
      children: [
        {
          index: true,
          path: "overview",
          element: <ViewerRequired><Dashboard title="" ><ViewerOverview /></Dashboard></ViewerRequired>
        },                
        {
          index: true,
          path: "invoices",
          element: <ViewerRequired><Dashboard title="" ><Invoices /></Dashboard></ViewerRequired>
        },
        {
          index: true,
          path: "profile",
          element: <ViewerRequired><Dashboard title="" ><ViewerProfile /></Dashboard></ViewerRequired>
        },        
      ]
    },
    {
      path: "creator",
      children: [
        {
          path: "",
          element: <Landing />
        },
        {
          index: true,
          path: "overview",
          element: <ViewerRequired><Dashboard title="" ><Overview /></Dashboard></ViewerRequired>
        },
        {
          path: "profile",
          element: <CreatorRequired><Dashboard title={t("backoffice.menu.profil")} ><EditProfil /></Dashboard></CreatorRequired>
        },
        {
          path: "settings",
          element: <CreatorRequired><Dashboard title={t("backoffice.menu.settings")} ><Settings /></Dashboard></CreatorRequired>
        },
        {
          path: "stats",
          element: <CreatorRequired><Dashboard title={t("backoffice.menu.stats")}  ><Stats /></Dashboard></CreatorRequired>
        },
        {
          path: "revenue",
          element: <CreatorRequired><Dashboard title={t("backoffice.menu.revenue")} ><Revenues /></Dashboard></CreatorRequired>
        },                                
        {
          path: "partners",
          element: <CreatorRequired><Dashboard title={t("backoffice.menu.partners")}><Partners /></Dashboard></CreatorRequired>
        },               
        {
          path: "create-event",
          element: <CreatorRequired><Page className="createEvent"><CreateEvent /></Page></CreatorRequired>,
        },                
    ]
  },
  {
    path: "studio/:id",
    element: <ViewerRequired><Studio /></ViewerRequired>
  },            
  { 
      path: "*",
      element: <Page><h1>Page introuvable</h1></Page>
  },
]}]



export default createBrowserRouter(routes);