import { NavLink } from "react-router-dom";

interface MenuItemProps {
    title: string;
    href: string;
    current?: boolean;
    onClick?: ()=>void;
}

export function MenuItem(props: MenuItemProps){
    return <NavLink to={props.href} reloadDocument={false}>
            {({ isActive, isPending, isTransitioning }) => (
                    <div onClick={() => {
                        if (props.onClick){
                            props.onClick();
                        }
                    }}>
                        <span className={"menu " + (isActive ? "current" : "")}>{props.title}</span>
                    </div>
                )}
            </NavLink>
}