import { Participant } from "@zoom/videosdk";
import { useContext, useEffect, useState } from "react";
import VideoWidget from "./video-widget";
import zoomContext from "../context/zoom-context";
import mediaContext from "../context/media-context";
import socketContext from "../context/socket-context";
import { ConnectedUser } from "../dto/connected-user.dto";
import { EventDTO } from "../../event/dto/event.dto";
import ActionBar from "./action-bar";
import { RightBar } from "./right-bar";
import BottomBar from "./bottom-bar";
import ChatMessage from "../dto/chat-message.dto";

export interface VideoGalleryProps{
    me: ConnectedUser;
    users: ConnectedUser[];
    event: EventDTO;
    setShowStartModal: Function;
    chatMessages: ChatMessage[]
}

export default function VideoGallery(props: VideoGalleryProps){
    const [cameraEnabled, setCameraEnabled] = useState(false);
    const [audioEnabled, setAudioEnabled] = useState(false);
    const [shareEnabled, setShareEnabled] = useState(false);
    const [videoParticipants, setVideoParticipants] = useState<Participant[]>([]);

    

    const zoomClient = useContext(zoomContext);
    const mediaStream = useContext(mediaContext);
    const socket = useContext(socketContext);

    const disableShare = () => {
        setShareEnabled(false);
        setShowScreenSharePanel(EnumShowScreenSharePanel.None);
    }

    useEffect(() => {
            updateVideos();


            zoomClient.getAllUser().forEach( (user) => {
                if (user.sharerOn) {
                    updateVideoScreenShare({state: 'Active', userId: user.userId});
                }       
            });

            
            zoomClient.on('active-share-change', updateVideoScreenShare);
            
            zoomClient.on("passively-stop-share", disableShare);
            

        return () => {
            zoomClient.off("passively-stop-share", disableShare);
            zoomClient.off('active-share-change', updateVideoScreenShare);
            zoomClient.off('user-added', updateVideos);
            zoomClient.off('user-removed', updateVideos);
            zoomClient.off("peer-video-state-change", updateVideos);
        }

    }, []);

    const toggleShare = () => {
        if (shareEnabled){
            mediaStream.stopShareScreen();
            setShareEnabled(false);
            setShowScreenSharePanel(EnumShowScreenSharePanel.None);

        } else {

            setShareEnabled(true);

            if ( mediaStream.isStartShareScreenWithVideoElement()) {
                setShowScreenSharePanel(EnumShowScreenSharePanel.Video);
                const screenShareVideo = document.getElementById('screen-share-video') as HTMLVideoElement;
                mediaStream.startShareScreen(screenShareVideo)
                .then(() => {
                    console.log("Screen shared")
                    })
                  .catch((error: any) => {
                    console.log(error)
                  })
              } else {
                setShowScreenSharePanel(EnumShowScreenSharePanel.Canvas);

                const screenShareCanvas = document.getElementById('screen-share-canvas') as HTMLCanvasElement;
                if (screenShareCanvas){
                    mediaStream.startShareScreen(screenShareCanvas)
                    .then(() => {
                          console.log("Screen shared")
                      })
                    .catch((error: any) => {
                      console.log(error)
                    })
                }
                }

              
            

        }
    }

    const updateVideoScreenShare = (payload: any) => {
        if (payload.state === 'Active') {
            setShareEnabled(true);
            setShowScreenSharePanel(EnumShowScreenSharePanel.Canvas);
            const screenShareCanvas = document.getElementById('screen-share-canvas') as HTMLCanvasElement;
            if (screenShareCanvas){
                mediaStream.startShareView(
                    screenShareCanvas,
                    payload.userId
                  )
            }
          } else if (payload.state === 'Inactive') {
            setShareEnabled(false);
            setShowScreenSharePanel(EnumShowScreenSharePanel.None);
            mediaStream.stopShareView()
          }
    }


    const toggleCamera = () => {
        setCameraEnabled(!cameraEnabled);
        if (cameraEnabled){
            mediaStream.stopVideo().then(() => {
                setCameraEnabled(false);
                updateVideos();

            }); 

        } else {
            let cameraParams = {};
            if (mediaStream.isSupportHDVideo()){
                console.log("HD Supported");
                cameraParams = {fullHD: true};
            }
            mediaStream.startVideo(cameraParams).then(() => {
                    setCameraEnabled(true);
                    updateVideos();

            }).catch((error: any) => {
            });
        }

    }

    const toggleAudio = () => {
        setAudioEnabled(!audioEnabled);
        audioEnabled ? mediaStream.muteAudio() : mediaStream.unmuteAudio().then(() => {
            setAudioEnabled(true);
        });
    }

    const updateVideos = () => {
        console.log("Updating videos")
        const zoomUserList = zoomClient.getAllUser();
        const videoParticipants : Participant[] | undefined = zoomUserList?.filter((user) => {
            if (user.bVideoOn && props.users.find( (socketUser) =>  user.displayName == socketUser.userName)?.role == 2) return true;
            if (user.bVideoOn && props.me.role == 2) { return true; }
            if (user.bVideoOn && props.me.role != 2 && props.me.userName == user.displayName) return true; 
            if (user.bVideoOn && props.users.find( (socketUser) => user.displayName == socketUser.userName)?.visible) return true;
            
            
            console.log("Navigating into the filter false :/");
            return false;
        });

        if (videoParticipants){
            setVideoParticipants(videoParticipants);
        }

    };

    useEffect( () => {
        const updateMe = (user: ConnectedUser) => {
        
            if (user.invited == false && user.role != 2){
                
                mediaStream.muteAudio().then(() => {
                    setAudioEnabled(false);
                }).catch((error: any) => {
                    console.log(error);
                });
            
            
                mediaStream.stopVideo().then(() => {   
                    setCameraEnabled(false);
                    updateVideos();
                }).catch((error: any) => {
                    console.log(error);
                });

            }
        }


        zoomClient.on('user-added', updateVideos);
        zoomClient.on('user-removed', updateVideos);
        zoomClient.on('user-updated', updateVideos);
        zoomClient.on("peer-video-state-change", updateVideos);
        socket.on("user-updated",  updateVideos);

        
        socket.on("me", updateMe); 

        updateVideos();

                    
        

        return () => {
            zoomClient.off("peer-video-state-change", updateVideos);
            zoomClient.off('user-added', updateVideos);
            zoomClient.off('user-removed', updateVideos);
            zoomClient.off('user-updated', updateVideos);
            socket.off("user-updated",  updateVideos);
            socket.off("me", updateMe); 
        }
    }, [props.users])

    enum EnumShowScreenSharePanel{
        None,
        Canvas,
        Video
    }
    const [showScreenSharePanel, setShowScreenSharePanel] = useState<EnumShowScreenSharePanel>(EnumShowScreenSharePanel.None);

    
    return <div style={{display:'flex', flex: 6, flexDirection: 'column'}}>
        <div className="vcontainer" style={{position: "relative", flex: 1}}>
            <div id="video-container" className={` ${showScreenSharePanel ? "studio-video-container-show-screen-share" : ""} `}>
                <video-player-container style={{flex: 1, display: "flex", flexDirection: shareEnabled ? "row" : "column", }}>
                    { shareEnabled && videoParticipants.map( ( participant, index ) => {
                        return <VideoWidget key={participant.userId} mediaStream={mediaStream} participant={participant} me={props.me} users={props.users}/>
                    })}
                    { !shareEnabled && [...Array(Math.ceil(videoParticipants.length/2))].map( (value, index) => {
                        return <div style={{flex: 1, flexDirection:"row", display: "flex"}}>
                                { videoParticipants.slice((2*index), (2* index) + 2).map( ( participant, index ) => {
                                    return <VideoWidget key={participant.userId} mediaStream={mediaStream} participant={participant} me={props.me} users={props.users}/>
                                    })
                                }
                        </div>
                    })}

                </video-player-container>
                <canvas id="screen-share-canvas" style={{display: showScreenSharePanel === EnumShowScreenSharePanel.Canvas ? 'block' : 'none' }}></canvas>
                <video id="screen-share-video" style={{display: showScreenSharePanel === EnumShowScreenSharePanel.Video ? 'block' : 'none'  }}></video>

            </div>
        </div>

        { <ActionBar className={"studio-desktop-action-bar"} shareEnabled={shareEnabled} audioEnabled={audioEnabled} cameraEnabled={cameraEnabled} event={props.event} me={props.me} setShowStartModal={props.setShowStartModal} socket={socket} toggleAudio={toggleAudio} toggleCamera={toggleCamera} toggleShare={toggleShare}/>}

        <div className="studio-mobile-bottom-bar">
                    <div style={{flex: 5, display: "flex"}}>
                        <RightBar className="studio-mobile-right-bar" users={props.users} me={props.me!} chatMessages={props.chatMessages} chatEnabled={props.event ? props.event.chatEnabled : false} socket={socket} />
                    </div>
                    <div>
                        { <ActionBar direction="column" className={"studio-mobile-action-bar"} shareEnabled={shareEnabled} audioEnabled={audioEnabled} cameraEnabled={cameraEnabled} event={props.event} me={props.me} setShowStartModal={props.setShowStartModal} socket={socket} toggleAudio={toggleAudio} toggleCamera={toggleCamera} toggleShare={toggleShare}/>}
                    </div>
                </div>

    </div>  
}