import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../../common/api";
import { Button, Col, Row } from "reactstrap";
import EventHeader from "../components/event-header";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getEvent } from "../event-slice";
import EventImage from "../components/event-image";
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { atcb_action } from "add-to-calendar-button";
import moment from "moment";
import { EventState } from "../dto/event.dto";

export function CheckoutEventSuccess() {
    const { id } = useParams();
    const event = useAppSelector((state) => state.events.event);
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        if (id && !event){
          dispatch(getEvent(id));
        }
    }, [id]);

    useEffect(() => {
        const url = new URL(window.location.href);
        const secretKey = url.searchParams.get('payment_intent')
        const secretKeySetup = url.searchParams.get('setup_intent')

        if (event && (secretKey || secretKeySetup)){
            api.post(`events/${id}/checkout/success`, {
                paymentIntentID: secretKey,
                setupIntentID: secretKeySetup

              });        
          }
    }, [event]);

    const config = {
        name: `[WeerLive] ${event?.title}`,
        description: `${event?.description}`,
        startDate: `${moment(event?.date).format("YYYY-MM-DD")}`,
        startTime: `${moment(event?.date).format("hh")}:${moment(event?.date).format("mm")}`,
        endTime: `${moment(event?.date).format("hh")}:${moment(event?.date).format("mm")}`,
        options: ["Google", "iCal"] as any,
        timeZone: "Europe/Paris",
        location: `https://app.weerlive.com/event/${event?._id}`,
        address: `https://app.weerlive.com/event/${event?._id}`,
      };
    return  <>
        { event && <Row className="justify-content-md-center"><Col md={7}>
            
            {(event.state == EventState.Draft || event.state == EventState.Live) && <><h1 style={{textAlign: "center", marginBottom: 30}}>
                Votre inscription au live est validé</h1>
                {event.state !== EventState.Live && <p style={{textAlign: 'center'}}>Nous vous enverrons un mail de rappel 1h avant l’évènement.</p>}
            </>}
            { event.state == EventState.Vod && <>
                <h1 style={{textAlign: "center", marginBottom: 30}}>
                L'achat de la VOD est réussi</h1>
            </>}  
            <div style={{ display: "flex", marginBottom: 20}}>
                <EventImage title={event.title} image={event.picture}  event={event} color={event.color}/>
            </div>
            <div style={{fontSize: '18px', fontWeight: 600, lineHeight: '20px', marginBottom: 30}}>
                {event.title} 
            </div>
            {event.state !== EventState.Live && event.state !== EventState.Vod && <Button color="primary" onClick={ (event) => {
                atcb_action(config, event.target as any);
            }}>Ajouter au calendrier</Button> } 
            
            
            {event && event.state === EventState.Live && <Link to={`/studio/${event._id}`}><Button color="primary">Accéder au live</Button></Link>}
            {event && event.state === EventState.Vod && <Link to={`/vod/${event._id}`}><Button color="primary">Accéder à la VOD</Button></Link>}

       
        </Col></Row>
        }
        </>
}