import { useEffect } from "react";
import { ScrollRestoration, Outlet, useNavigation, useParams, useNavigate } from "react-router-dom";


export default function Root() {


    return (<>
            <Outlet  />
            <ScrollRestoration />
        </>
    );
}
