import { useState } from "react";
import { Button, Form } from "reactstrap";
import send from "../../../assets/studio/send.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";


export interface ChatFormWidgetProps{
    onMessage(message: string): void;
}

export default function ChatFormWidget(props: ChatFormWidgetProps){
    const [chatMessage, setChatMessage] = useState<string>("");

    return <Form onSubmit={ (event) => {
            event.preventDefault();
            if (chatMessage === "") return;
            props.onMessage(chatMessage);
            setChatMessage("");
        }}
        style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%"
            
        }}>
            <input type="text" value={chatMessage} style={{flex: 1, backgroundColor: "#424242", borderRadius: 10, color: "white", padding: 10, fontSize: '16px', border: "none", marginRight: 10}} placeholder="Écrire un message" onChange={ (event) => { setChatMessage(event.target.value)} }></input>
            <Button type="submit" style={{backgroundColor: "#7580FC"}}><FontAwesomeIcon icon={faPaperPlane} /></Button>
        </Form>   
}
        