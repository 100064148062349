import React, { useEffect, useState } from "react";
import './revenue-simulator.css';
import { useAppSelector } from "../../../hooks";
import { Trans } from "react-i18next";
import Info from "../../../theme/components/info";

const calculateRevenue = (price: number, duration: number, attendees: number, commission: number, isPerMinute: boolean) => {
  let revenue = price * attendees;
  if (isPerMinute){
    revenue = price * duration * attendees;
  }
  
  let revenueWithoutCommission = revenue - (revenue * (commission / 100));
  return revenueWithoutCommission;
}


export default function RevenueSimulator() {
  const [duration, setDuration] = useState(30);
  const [price, setPrice] = useState(1);
  const [attendees, setAttendees] = useState(10);
  const [type, setType] = useState<string>("minute");

  const config = useAppSelector((state) => state.app.config);

  const [revenue, setRevenue] = useState(calculateRevenue(1, 30, 10, config?.commission || 5, true));


  const updatePrice = (price: number, duration: number, attendees: number, isPerMinute: boolean) => {
    setRevenue(calculateRevenue(price, duration, attendees, config?.commission || 5, isPerMinute));
  }

  useEffect(() => {
    console.log(config);
    updatePrice(price, duration, attendees, type === "minute");
  }, [config]);
  
  return <div className="RevenueSimulator">
    <span><Trans i18nKey="revenueSimulator.amount">Avec un tarif de </Trans><input type="text" value={price} onChange={ (event : React.ChangeEvent<HTMLInputElement>) => {
      const price = parseFloat(event.target.value);
      if (!isNaN(price) && price > 0) {
        setPrice(price);
        updatePrice(price, duration, attendees, type === "minute");
      }else {
        setPrice(0);
        updatePrice(0, duration, attendees, type === "minute");
      }
    }}></input> <Trans i18nKey="revenueSimulator.each">€ par</Trans> <select onChange={ (e) => {setType(e.target.value); updatePrice(price, duration, attendees, e.target.value == "minute")}}><option value="minute"><Trans i18nKey="revenueSimulator.minute">minute</Trans></option><option value="event"><Trans i18nKey="revenueSimulator.event">évènement</Trans></option></select></span>
    <span>{type == "minute" && <><Trans i18nKey="revenueSimulator.live">pour un live de</Trans> <input value={duration} type="text" onChange={ (event : React.ChangeEvent<HTMLInputElement>) => {
      const duration = parseFloat(event.target.value);
      if (!isNaN(duration) && duration > 0) {
        setDuration(duration);
        updatePrice(price, duration, attendees, type === "minute");
      }else {
        setDuration(0);
        updatePrice(price, 0, attendees, type === "minute");
      }
    }}></input> <Trans i18nKey="revenueSimulator.minutes">minutes</Trans></>} <Trans i18nKey="revenueSimulator.with">with</Trans> <input type="text" value={attendees} onChange={(event : React.ChangeEvent<HTMLInputElement>) => {
      const attendees = parseInt(event.target.value);
      if (!isNaN(attendees) && attendees > 0) {
        setAttendees(attendees);
        updatePrice(price, duration, attendees, type === "minute");
      }else {  
        setAttendees(0);
        updatePrice(price, 0, attendees, type === "minute");
  
      }
    }}></input> <Trans i18nKey="revenueSimulator.viewers">participants</Trans></span>
    <span> <Trans i18nKey="revenueSimulator.receive">recevez</Trans> <span className="attention">{revenue} $</span><Info style={{top: -20}} message="Ceci est un message très long Ceci est un message très long Ceci est un message très long Ceci est un message très long Ceci est un message très long Ceci est un message très long" /></span>
  </div>;
}