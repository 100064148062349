import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";



const ResetPasswordSuccess = (props : any) => {


    return <>
        <p>Password reset successfully</p>
       <Link to={'/login'}><Button color="primary">Login</Button></Link>
    </>
};

export default ResetPasswordSuccess;
