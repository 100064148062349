import ChatMessage from "../dto/chat-message.dto";
import { ConnectedUser } from "../dto/connected-user.dto";
import ChatFormWidget from "./chat-form-widget";
import ChatMessageWidget from "./chat-message-widget";
import { useEffect, useRef, useState } from "react";
import "./chat-widget.css"

export interface ChatWidgetProps{
    chatMessages : ChatMessage[];
    chatClient: any;
    users: ConnectedUser[];
    currentUser : ConnectedUser;
    onClickPromote: Function;
    onClickInvite: Function;
    onClickAlert: Function;
    onClickBan: Function;
}

export default function ChatWidget(props: ChatWidgetProps){
    const [lock, setLock] = useState(false);
    const listRef = useRef<any>();

    useEffect( () => {
        
        if (!lock && props.chatMessages.length > 0){
            listRef.current?.lastElementChild?.scrollIntoView()
        }
    

    }, [props.chatMessages]);

    const onMessage = (message: string) => {
        if (message === "") return;
        props.chatClient.emit("chat-message", message);
    }

    return <div style={{display:'flex', flexDirection: 'column', justifyContent: 'space-around', flex: 1,/* position: "relative"*/}}>
        <div ref={listRef} className="studio-chat-message-widget-list-container">
            {props.chatMessages.map( (message, index) => {
                const user = props.users.find((user) => message.userID == user.id)
                return <ChatMessageWidget key={index} message={message} me={props.currentUser} user={user ? user : {} as any}
                onClickAlert={props.onClickAlert}
                onClickBan={props.onClickBan}
                onClickInvite={props.onClickInvite}
                onClickPromote={props.onClickPromote} />
            })}
        </div>
        <div className="studio-chat-form-widget-container">
            <ChatFormWidget onMessage={onMessage} />
        </div>

    </div>
}