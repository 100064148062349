import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { list } from "../../login/login-slice";
import { Col, Row } from "reactstrap";
import CreatorImage from "../../creator/components/creator-image";
import moment from "moment";

export default function AdminUsers() {
    
    const dispatch = useAppDispatch();
    const users = useAppSelector( state => state.login.users )
    useEffect( () => {
        dispatch(list());   
    }, []);

  return <div>
    
    {users.map ( (user) => {
        return  <Row className="secondary align-content-center" style={{borderRadius: 20,  padding: 30, marginTop: 10}}>
        <Col md={{size: 1}}>
            <CreatorImage image={user.picture} userName={user.userName} isLive={user.profile?.isLive} color={user.color} />
        </Col>
        <Col md={{size: 3}}>
        <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}>{moment(user.createdAt).format('DD/MM/YYYY HH:mm')}</h1>
            <p>{user.userName}</p>
        </Col>
        <Col md={{size: 4}}>
        <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}>{moment(user.createdAt).format('DD/MM/YYYY HH:mm')}</h1>
            <p style={{fontSize: 12}}>{user.email}</p>
        </Col>        
        <Col md={{size: 1}}>
        <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}>Pays</h1>
            <p>{user.country}</p>
        </Col>                
        <Col md={{size: 1}} style={{textAlign: 'center'}}>
            <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}>Créateur</h1>
            <p>{user.profile ? "Oui" : "Non"}</p>
        </Col>

    </Row> 
        
    })}
  </div>;
}      