import { Form, Link, useNavigate } from "react-router-dom";
import FormInput from "../../../theme/components/form-input";
import { Alert, Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup"
import { useAppDispatch, useAppSelector, useYupValidationResolver } from "../../../hooks";
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import EventImage from "./event-image";
import CategoryDTO from "../dto/category.dto";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { EventDTO, EventState } from "../dto/event.dto";
import api from "../../../common/api";
import { getEvent, updateThumbnail } from "../event-slice";
import { get } from "lodash";
import BASE_URL from "../../../common/urls";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

import useModal from "../../../theme/hooks/use-modal";
import { getConfig } from "../../../platforms/config";
import { Trans } from "react-i18next";
import { t } from "i18next";
import ImageCropperModal from "../../../theme/components/image-cropper-modal";
interface EditEventFormProps {
    onSubmit?: SubmitHandler<EditEventSchema | EditVODEventSchema>;
    error?: string;
    event: EventDTO;
}

export type EditEventSchema = {
  title: string,
  description: string,
  category: string,
  chatEnabled: boolean,
  seats: Number,
  type: number,
  time: string,
  color: string,
  isPublic: boolean
}

export type EditVODEventSchema = {
  title: string,
  description: string,
  category: string,
  color: string
}



const CreateProfilValidationSchema = yup.object({
  title: yup.string().min(2).required(),
  description: yup.string().min(2).required(),
  category: yup.string().required(),
  photo: yup.mixed().notRequired(),
  seats: yup.number().default(980).required("Seats number is required").min(1).max(980),
  chatEnabled: yup.boolean().required("Chat is Required"),
})


const EditVODEventValidationSchema = yup.object({
  title: yup.string().min(2).required(),
  description: yup.string().min(2).required(),
  photo: yup.mixed().notRequired(),
});



export default function EditEventForm(props: EditEventFormProps) {



    const [colorIndex, setColorIndex] = useState(0);

    const switchColor = () => {
        let nextIndex = colorIndex + 1;
        if (nextIndex >= colors.length){
          nextIndex = 0;
        }
        setColorIndex(nextIndex);
    }


    const colors = getConfig().colors;


    const [title, setTitle] = useState<string>("");
    const inputFile = useRef<any>(null);
    const [error, setError] = useState(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
      setTitle(props.event.title);
    }, [props.event.title])

    useEffect(() => {
      if (props.event.seats){
        setSeats(props.event.seats);
      }
    }, [props.event.seats])

    useEffect(() => {
      if (props.event.color){
        setColorIndex(colors.indexOf(props.event.color));
      }
    }, [props.event.color])

    let type;
    if (props.event.state == EventState.Vod){
      type = EditVODEventValidationSchema;
    }else{
      type = CreateProfilValidationSchema;
    }

    const resolver = useYupValidationResolver(type)
    
    let form = useForm<EditEventSchema | EditVODEventSchema>({resolver, defaultValues: {title: props.event?.title, description: props.event?.description, category: props.event?.category, chatEnabled: props.event?.chatEnabled, seats: props.event.seats}})

    const navigation = useNavigate();
    const categories = useAppSelector((state) => state.events.categories);

    const [showEditor, setShowEditor] = useState(false);
    const [cropperThumbnailURL, setCropperThumbnailURL] = useState<String>("");

    const [thumbnail, setThumbnail] = useState<File | null>(null);
    const [thumbnailURL, setThumbnailURL] = useState<string>("");
    const modal = useModal();

    const onChangeInputFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file){
        setCropperThumbnailURL(URL.createObjectURL(file));
        setShowEditor(true);
      }
  }

  const onCrop = (blob: Blob) => {
    
    let file = new File([blob], "file.png", {type:"image/png"});
    setThumbnail(file);
    setThumbnailURL(URL.createObjectURL(blob));
    dispatch(updateThumbnail({id: props.event._id, file: file}));
    setShowEditor(false);
  }



    const onSubmit = (data: EditEventSchema | EditVODEventSchema) => {
      if (props.event.state !== EventState.Vod){
        const dataEvent = data as EditEventSchema;
        dataEvent.seats = seats;
      }
      data.color = colors[colorIndex];

      api.put(`/events/${props.event._id}`, data).then((response) => {
        if (thumbnail){
          dispatch(getEvent(props.event._id));
          props.onSubmit && props.onSubmit(data);

        }else{
          dispatch(getEvent(props.event._id));
          props.onSubmit && props.onSubmit(data);

        }
      });
    }

    const [seats, setSeats] = useState(980);


    return <Form method="post" onSubmit={form.handleSubmit(onSubmit)}>
    { error && <Alert color="danger">
      { props.error }
    </Alert> }

    <h2><Trans i18nKey={"backoffice.creator.edit_event.edit_event"}>Editer le live</Trans></h2>
    <Row>
      <Col md={6}>
        <FormGroup>
          <Label for="title">
            <Trans i18nKey="backoffice.creator.create_event.title">Titre</Trans>
          </Label>
          <FormInput
            id="title"
            placeholder={t("backoffice.creator.create_event.title")}
            name="title"
            register={form.register}
            invalid={form.formState.errors.title}
            onChange={ (e) => { setTitle(e.target.value)} }
          />
          <FormFeedback>
            { form.formState.errors.title?.message }
          </FormFeedback>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label for="exampleState">
            <Trans i18nKey="backoffice.creator.create_event.category">Category</Trans>
          </Label>
          <FormInput
            id="category"
            name="category"
            type="select"
            register={form.register}
            invalid={form.formState.errors.category}
          >
              { categories.map((category : CategoryDTO) => {
                return <option value={category._id}>{category.name}</option>
              })}

          </FormInput>
          <FormFeedback>
            { form.formState.errors.category?.message }
          </FormFeedback>
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <FormGroup>
          <Label for="formDescription">
            <Trans i18nKey="backoffice.creator.create_event.describe">Description</Trans>
          </Label>
          <FormInput
            style={{height: 180}}
            id="formDescription"
            name="description"
            type="textarea"
            register={form.register}
            invalid={form.formState.errors.description}

          />
          <FormFeedback>
            { form.formState.errors.description?.message }
          </FormFeedback>
        </FormGroup>
        { props.event.state !== EventState.Vod && <>

        <FormGroup switch style={{marginBottom: 20}}>
                  <Label for="chatEnabled">
                    <Trans i18nKey="backoffice.creator.create_event.enable_chat">Chat</Trans>
                  </Label>
                <FormInput
                    id="chatEnabled"
                    name="chatEnabled"
                    type="switch"
                    register={form.register}
                    invalid={(form.formState.errors as FieldErrors<EditEventSchema>).chatEnabled}
                    style={{marginRight: 10}}l

                  />
                  <FormFeedback>
                    { (form.formState.errors as FieldErrors<EditEventSchema>).chatEnabled?.message }
                  </FormFeedback>
        </FormGroup>
        <FormGroup>
                  <Label for="formSeats">
                    <Trans i18nKey={"backoffice.creator.create_event.seats"}>Nombre de place</Trans>
                  </Label>
                  <Row>
                  <Col md={10} style={{display:"flex", alignItems:"center", justifyItems:"center"}}>
                    <Input
                      type="range"
                      register={form.register}
                      min={props.event.subscribers?.length}
                      max={980}
                      step={1}
                      value={seats}
                      onChange={ ( event )=> {
                        setSeats(parseInt(event.target.value));

                      }}
                    />
                  </Col>
                  <Col md={2}>
                  <FormInput
                    id="formSeats"
                    name="seats"
                    type="number"
                    register={form.register}
                    value={seats}
                    invalid={(form.formState.errors as FieldErrors<EditEventSchema>).seats}
                    onChange={ (event) => {
                      setSeats(event.target.value)
                    }}
                  />
                  </Col>
                  </Row>
                  <FormFeedback>
                    { (form.formState.errors as FieldErrors<EditEventSchema>).seats?.message}
                  </FormFeedback>
                </FormGroup></> }
      </Col>
      <Col md={6}>
        <FormGroup style={{display: 'flex', flexDirection: 'column'}}>
          <Label for="exampleState">
            <Trans i18nKey="backoffice.creator.create_event.thumbnail">Thumbnail</Trans>
          </Label>
          <EventImage title={title} image={thumbnail ? thumbnailURL : props.event.picture ? props.event.picture  : ""} isPreview={true} color={colors[colorIndex]} onSwitchColor={() => {
            switchColor();
          }}/>
          <p style={{color:'#8A8A8A', width: '100%', textAlign: 'center'}}><FontAwesomeIcon icon={faTriangleExclamation} /> <Trans i18nKey={"backoffice.creator.create_event.upload_advice"}>Recommanded size : 1920x1080 (16:9)</Trans></p>
          <input type="file" id="imgupload" style={{display:"none"}}  ref={(ref) => inputFile.current = ref} onChange={ onChangeInputFile }/>

          <Button color="primary" style={{marginTop:'10px'}} onClick={ () => {  inputFile.current.click(); }}><Trans i18nKey={"backoffice.creator.create_event.upload"}>Uploader une image</Trans></Button>
        </FormGroup>
      </Col>
    </Row>

      
    <ImageCropperModal onCancel={() => {
      setShowEditor(false);
    }} visible={showEditor} onCrop={(blob: Blob) => {
      onCrop(blob);
    }} image={cropperThumbnailURL} aspectRatio={16.0/9.0} />



    <Button style={{marginRight: 20}} color="primary" onClick={ () => {
      modal.show("Suppression du live", <>Êtes vous sûr de vouloir supprimer ce live ?</>, [{
        label: "Oui",
        onClick: () => {
          api.delete(`/events/${props.event._id}`).then(() => {
            navigation("/creator/overview");
          })
        }
      }, {label: "Non", onClick: () => {}}]);
    }}>
      Supprimer
    </Button>

    <Button color="primary">
      <Trans i18nKey="form.save">Sauvegarder</Trans>
    </Button>
</Form>;
}
