import { CSSProperties } from "react";
import BASE_URL from "../../../common/urls";
import "./creator-image.css";

interface CreatorImageProps{
    image?: string;
    userName: string;
    style?: CSSProperties
    backgroundColor?: string;
    isLive?: boolean;
    color?: string;
} 

export default function CreatorImage(props: CreatorImageProps){
    return <div className={`creator-image-container ${ props.isLive && "creator-image-live"}`}>
        { !props.image &&<div> 
            <h1 style={{backgroundColor: props.color ? props.color : undefined}}>{props.userName[0]}</h1>
        </div> }
       { props.image && props.image != "" && <img src={props.image} style={{...props.style}} /> }
       <div className="live-container"><div className="live">Live</div></div>

    </div>;
}