import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { ConnectedUser } from "../dto/connected-user.dto";
import { useState } from "react";

export interface UserWidgetProps{
    user : ConnectedUser
    me: ConnectedUser
    

    onClickPromote?: Function;
    onClickInvite?: Function;
    onClickAlert?: Function;
    onClickBan?: Function;
}


export function UserActionDropdown(props: UserWidgetProps){
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

    return <>{ props.me.role > 0 && props.me.role > props.user.role &&
        <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{justifySelf: 'flex-end'}}>
            <DropdownToggle caret size="md"></DropdownToggle>
            <DropdownMenu>
              { props.me.role == 2 && <DropdownItem onClick={ ( e ) => props.onClickPromote && props.onClickPromote(props.user) }>{ props.user.role >0 ? "Unpromote user" : "Promote as moderator" }</DropdownItem> }
              { props.me.role == 2 && <DropdownItem onClick={ ( e ) => props.onClickInvite && props.onClickInvite(props.user) }>{ props.user.role >0 ? "Un invite user" : "Invite user" }</DropdownItem> }
              <DropdownItem onClick={ ( e ) => props.onClickAlert && props.onClickAlert(props.user) }>Send alert</DropdownItem>
              <DropdownItem onClick={ ( e ) => props.onClickBan && props.onClickBan(props.user) }>Ban</DropdownItem>          
            </DropdownMenu>
          </Dropdown> } </>
}