import { Button, Col, Row, Spinner } from "reactstrap";
import { EventCard } from "../../event/components/event-card";
import { useEffect } from "react";
import { listEventsByCreator, listMyEvents } from "../../event/event-slice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Link, NavLink, useNavigate, useNavigation } from "react-router-dom";
import { getStats } from "../creator-slice";
import DateFilter from "../components/date-filter";
import { EventState } from "../../event/dto/event.dto";
import { Trans } from "react-i18next";
import useModal from "../../../theme/hooks/use-modal";
import { CreateLiveLink } from "../components/create-live-link";
import Loading from "../../../theme/components/loading";

export function Overview(){
    const user = useAppSelector((state) => state.login.user);
    const events = useAppSelector((state) => state.events.events);
    const eventsLoading = useAppSelector((state) => state.events.loading);

    const stats = useAppSelector((state) => state.creator.stats);
    const statsLoading = useAppSelector((state) => state.creator.loading);
    const currentFilter = useAppSelector((state) => state.creator.currentFilter);
    
    const modal = useModal();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    useEffect(() => {
      if (user){
        dispatch(listMyEvents());
        dispatch(getStats());
      }
    }, [user]);

    return <div>
        <Row className="align-content-center">
            <Col md={2}>
                <h1 style={{fontSize: 28}}>Overview</h1>
            </Col>
            { /* <DateFilter active={currentFilter} /> */ }

        </Row>
        <Row style={{marginTop: 10}}>
             <Col md={12} xl={7} >
                { statsLoading ? <Loading /> :
                <Row className="secondary align-content-center text-center" style={{marginBottom: 10, borderRadius: 20, padding: 10, minHeight: 125}}>
                    <Col md={{size: 3}} xs={{size: 6}} style={{}}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.overview.stream"}>Stream</Trans></h2>
                        <h3>{ stats?.liveCount } </h3>
                    </Col>
                    <Col md={{offset: 1, size: 3}} xs={{size: 6}} style={{}}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.overview.views"}>Views</Trans></h2>
                        <h3>{ stats?.views } </h3>
                    </Col>
                    <Col md={{offset: 1, size: 3}} xs={{size:12}}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.overview.revenue_live"}>Revenu Live</Trans></h2>
                        <h3>{ stats?.revenueLive } </h3>
                    </Col>
                </Row>}
            </Col> 
            <Col className="secondary align-content-center text-center" style={{borderRadius: 20, padding: 20, height: 120}} xl={{
                offset: 1,
                size: 4
            }}>
                <h2 style={{fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.overview.create_next_live"}>Créez ou programmez votre prochain live</Trans></h2>
                <CreateLiveLink>
                    <Button color="primary"><Trans i18nKey={"backoffice.creator.overview.create_live"}>Créer un live</Trans></Button>
                </CreateLiveLink>
            </Col>
        </Row>
        { eventsLoading ? <Loading /> : <>  
        <h1 style={{fontSize: 22, marginTop: 40}}><Trans i18nKey={"backoffice.creator.overview.in_live"}>Lives en cours</Trans></h1>
        <Row>
            {events.map((event) => {
                if (event.state === EventState.Live){
                    return  <Col md={12} lg={6}>
                        <NavLink to={`/event/${event._id}`} style={{textDecoration: "none"}}><EventCard key={event._id} event={event} /></NavLink>
                    </Col>
                }else {
                    return <></>
                }
 
            })}               
        </Row>  
        <h1 style={{fontSize: 22, marginTop: 40}}><Trans i18nKey={"backoffice.creator.overview.live_scheduled"}>Lives programmés</Trans></h1>
        <Row>
            {events.map((event) => {
                if (event.state === EventState.Draft){
                    return  <Col md={12} lg={6}>
                        <NavLink to={`/event/${event._id}`} style={{textDecoration: "none"}}><EventCard key={event._id} event={event} /></NavLink>
                    </Col>
                }else {
                    return <></>
                }
 
            })}               
        </Row>    
        <h1 style={{fontSize: 22, marginTop: 40}}><Trans i18nKey={"backoffice.creator.overview.replay"}>Replays disponibles</Trans></h1>
        <Row>
            {events.map((event) => {
                if (event.state === EventState.Vod){
                    return  <Col md={12} lg={6}>
                        <NavLink to={`/event/${event._id}`} style={{textDecoration: "none"}}><EventCard key={event._id} event={event} /></NavLink>
                    </Col>
                }else {
                    return <></>
                }
 
            })}                
        </Row> </>  }
    </div>
}