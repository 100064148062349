import { Button, Col, Container, Row } from "reactstrap";
import { Page } from "../../../theme/templates/page";
import RevenueSimulator from "../../marketing/pages/revenue-simulator";
import "./landing.css"
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { getPlaformImage } from "../../../platforms/config";
import { LandingCard } from "../../../theme/components/landing-card";
import { RightImageContent } from "../../../theme/components/right-image-content";
import { LeftImageContent } from "../../../theme/components/left-image-content";

const starGreen = getPlaformImage("homepage/star-green.svg");
const yellowRound = getPlaformImage("homepage/yellow-round.svg");
const square = getPlaformImage("homepage/square.svg");
const pink = getPlaformImage("homepage/pink.svg");
const pointer = getPlaformImage("homepage/pointer.svg");
const live = getPlaformImage("homepage/live.svg");
const send = getPlaformImage("homepage/send.svg");
const euro = getPlaformImage("homepage/euro.svg");
const music = getPlaformImage("homepage/music.svg");
const sports = getPlaformImage("homepage/sports.svg");
const play = getPlaformImage("homepage/play.svg");
const entertainment = getPlaformImage("homepage/entertainment.svg");
const coaching = getPlaformImage("homepage/coaching.svg");
const lifestyle = getPlaformImage("homepage/lifestyle.svg");
const fitness = getPlaformImage("homepage/fitness.svg");
const liveevents = getPlaformImage("homepage/liveevents.svg");
const chess = getPlaformImage("homepage/chess.svg");
const tutorials = getPlaformImage("homepage/tutorials.svg");
const certify = getPlaformImage("homepage/certify.svg");


export default function Landing() {
    const { t } = useTranslation(); 
    return <Page fluid={true} className="landing creator container-fluid">
        <Container>
        <Row className="header align-items-center">
            <Col xl={5}>
                <h1><Trans i18nKey={"landing.creator.header.title"}>Votre talent en lumière, <br />Votre passion rémunéée</Trans></h1>
                <p><Trans Trans i18nKey={"landing.creator.header.subtitle"}>Plongez dans le monde du streaming live où votre créativité rencontre la monétisation. Chez Weerlive, nous transformons chaque performance en expérience unique. Connectez-vous avec votre communauté, partagez votre passion tout en monétisant votre contenu.</Trans></p>
                <Link to={"/create-profil"}><Button color="primary"><Trans i18nKey={"landing.creator.header.call_to_action"}>Rejoignez la révolution du streaming</Trans></Button></Link>
            </Col>
            <Col xl={7} className="photo-header">
            <img className="landing-img-top" src={getPlaformImage('homepage/landing-top.png')} />
                {/* <img className="monetize" src={require('../../../assets/homepage/monetize.png')} />
                <img src={require('../../../assets/homepage/livestream.png')} />
                <img className="streamer-2" src={require('../../../assets/homepage/streamer-2.png')} />
                <img className="streamer" src={require('../../../assets/homepage/streamer.png')} />

                <img className="viewers"  src={require('../../../assets/homepage/viewers-count.png')} />
                <img className="payment" src={require('../../../assets/homepage/payment.png')} /> */}
            </Col>

        </Row>
        <Row className="foursteps">
            <h2 className="text-center" style={{marginBottom: 50}}><Trans i18nKey={"landing.creator.4_easy_steps"}>En route vers le sommet En 4 étapes simples</Trans></h2>
        </Row>  
        <div style={{display: 'flex'}} className="landing-step justify-content">
            <LandingCard step={1} picto={pointer} icon={starGreen} title={<Trans i18nKey={"landing.creator.inscription"}>INSCRIPTION</Trans>} description={t("landing.creator.incription_description")}/>
            <LandingCard step={2} picto={live} icon={yellowRound} title={<Trans i18nKey={"landing.creator.create_live_in_a_minute"}>Créer son live en 1 min</Trans>} description={t("landing.creator.create_live_in_a_minute_description")} />
            <LandingCard step={3} picto={send} icon={square} title={<Trans i18nKey={"landing.creator.share"}>Partager sur les réseaux</Trans>} description={t("landing.creator.share_description")} />
            <LandingCard step={4} picto={euro} icon={pink} title={<Trans i18nKey={"landing.creator.monetize"}>Monétiser</Trans>} description={t("landing.creator.monetize_description")}/>          
        </div>
        <Row className='justify-content-center' style={{marginTop: 100}}>              
            <h2 className="text-center" style={{marginBottom: 50}}><Trans i18nKey={"landing.creator.revenue_simulator_title"}>Notre simulateur de revenus</Trans></h2>
            <p className="simulator-text"><Trans i18nKey={"landing.creator.revenue_simulator_description"}>Curieux de connaître vos potentiels gains ? Utilisez notre simulateur pour estimer vos revenus. Indiquez votre tarif, le nombre de participants et la durée de votre live. Visualisez vos opportunités de gain et planifiez votre succès.</Trans></p>
            <RevenueSimulator />
            <div className="text-center" style={{marginTop: 40}}>
                <Link to={"/creator/create-event"}><Button className="create-button" color="primary"><Trans i18nKey={"landing.creator.revenue_simulator_cta"}>Créer un live</Trans></Button></Link>
            </div>
        </Row>             
        <Row className="separator">
            <h2 className="text-center explore"><Trans i18nKey={"landing.creator.explore"}>EXPLOREZ LES AVANTAGES UNIQUES DE NOTRE PLATEFORME</Trans></h2>
        </Row>
        <Row className="justify-content-center service-options">
        <RightImageContent title={t("landing.creator.live_options_title")} description={t("landing.creator.live_options_description")}  image={getPlaformImage('live-options.png')}/>
        <LeftImageContent title={t("landing.creator.multiviewer_title")}
                        description={t("landing.creator.multiviewer_description")}  
                        image={getPlaformImage('multiviewer.png')}/>
  
        <RightImageContent title={t("landing.creator.cohosting_title")} description={t("landing.creator.cohosting_description")}  image={getPlaformImage('revenue-share.png')}/>

        <LeftImageContent title={t("landing.creator.record_title")} 
                        description={t("landing.creator.record_description")}  
                        image={getPlaformImage('homepage/replays.png')}/>
  
        <RightImageContent title={t("landing.creator.revenue_management_title")} description={t("landing.creator.revenue_management_description")}  image={getPlaformImage('revenue-management.png')}/>
        <LeftImageContent title={t("landing.creator.affiliation_title")} 
                        description={t("landing.creator.affiliation_description")}  
                        image={getPlaformImage('homepage/affiliation.png')}/>
        </Row>
        <Row style={{marginTop: 100, marginBottom: 50}}>
            <h2 className="text-center"><Trans i18nKey={"landing.creator.join_streamers"}>Rejoignez d’autres streamers sur Weerlive</Trans></h2>
        </Row>
        </Container>

            
        <Row className="streamer-card-container">
        <img className="img-fluid" src={getPlaformImage('homepage/creators.png')} style={{padding: 0}}/>
            {/* <StreamerCard mainImage={require('../../../assets/homepage/streamer-card-1.png')} bottomImage={require('../../../assets/homepage/streamer-card-1-bottom.png')}/>
            <StreamerCard mainImage={require('../../../assets/homepage/streamer-card-2.png')} bottomImage={require('../../../assets/homepage/streamer-card-2-bottom.png')}/>
            <StreamerCard mainImage={require('../../../assets/homepage/streamer-card-3.png')} bottomImage={require('../../../assets/homepage/streamer-card-3-bottom.png')}/>
            <StreamerCard mainImage={require('../../../assets/homepage/streamer-card-4.png')} bottomImage={require('../../../assets/homepage/streamer-card-4-bottom.png')}/> */}
        </Row>
        <Container>
        <Row className="creativity text-center">
            <div className="bloc" style={{top: 80}}>
                <img src={music} className="music"/>
                <img src={sports} className="sports"/>
                <img src={play}  className="play"/>
                <img src={entertainment} className="entertainment"/>
                <img src={coaching} className="coaching"/>
            </div>
            <div>
                <h1><Trans i18nKey={"landing.creator.creativity"}>Votre créativité sans limite</Trans></h1>
                <div className="bloc">
                    <img src={lifestyle} className="lifestyle"/>
                    <img src={fitness} className="fitness"/>
                    <img src={liveevents} className="liveevents"/>
                    <img src={chess} className="chess"/>
                    <img src={tutorials} className="tutorials"/>
                    <img src={certify} className="certify"/> 
                </div>
            </div>

        </Row>
        </Container>
    </Page>
}