import { useTranslation } from "react-i18next";
import { Page } from "../../../theme/templates/page";


export default function Terms() {
    const { t } = useTranslation(); 

    return (
        <Page>
            <h1>{t("terms.title")}</h1>
            <p>{t("terms.text")}</p>
        </Page>
    )

}