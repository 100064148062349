import React from 'react';
import { Col } from 'reactstrap';

export enum DateFilterEnum{
    ALL = 'all',
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    LAST_7_DAYS = 'last_7_days',
    LAST_30_DAYS = 'last_30_days',
}

export interface DateFilterProps{
    onFilter?: (date: DateFilterEnum) => void;
    active: DateFilterEnum;
    colSize?: number;
}

export const styles = {
    filter: {
        color: "#8A8A8A",
        cursor: "pointer"
    }

}

export default function DateFilter(props: DateFilterProps){
    return <Col md={5} style={{listStyleType: 'none', justifyItems: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
        <a style={{...styles.filter, textDecoration: 'underline'}}>Last 7 days</a>
        <a style={{color: "#8A8A8A", cursor: "pointer"}}>Last 30 days</a>
        <a style={{color: "#8A8A8A", cursor: "pointer"}}>Last Year</a>
        <a style={{color: "#8A8A8A", cursor: "pointer"}}>All</a>
    </Col>
}