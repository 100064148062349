import { useTranslation } from "react-i18next";
import { Page } from "../../../theme/templates/page";


export default function Legal() {
    const { t } = useTranslation(); 
    return ( <Page>
            <h1>{t("legal.title")}</h1>
            <p>{t("legal.text")}</p>
        </Page>
    )
}