import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import api from '../../common/api';
import { CreateEventDTO } from './dto/create-event.dto';
import { EventDTO } from './dto/event.dto';
import CategoryDTO from './dto/category.dto';
import { filter } from 'lodash';
import { InvoiceDTO } from './dto/invoice.dto';
import Transfert from './dto/transfert.dto';




interface EventState {
  error: string | undefined,
  loading: boolean
  events: any[],
  categories: CategoryDTO[],
  event?: EventDTO,
  eventCount?: number,
  eventOffset?: number,
  eventNext?: number,
  thumbnailUploaded?: boolean,
  invoices: InvoiceDTO[],
  transferts : Transfert[]
  revenues: number
}


export const createEvent = createAsyncThunk('event/create', async (event: CreateEventDTO) => {
  try{
    const response = await api.post("events", event)
    return response.data;
  }catch(e: any){
    throw new Error(e.response.data.message);
  }
  
});


export const listMyTransferts = createAsyncThunk('event/transferts/list', async () => {
  const response = await api.get(`transfert/me`)
  return response.data
});

export const listCategories = createAsyncThunk('event/category/list', async () => {
  const response = await api.get(`events/categories`)
  return response.data
});




export const listMyEvents = createAsyncThunk('event/me', async () => {
  const response = await api.get(`events/me`)
  return response.data
});


export const listAllEvents = createAsyncThunk('event/listAll', async (offset) => {
  const response = await api.get(`events`)
  return response.data
});

export const listHomePageEvents = createAsyncThunk('event/homePage', async (offset) => {
  const response = await api.get(`events/homepage`)
  return response.data
});



export const adminListAllEvents = createAsyncThunk('event/admin/listAll', async () => {
  const response = await api.get(`events/admin`)
  return response.data
});



export const listInvoices = createAsyncThunk('event/listInvoices', async () => {
  const response = await api.get(`events/invoices`)
  return response.data
});


export const listEventByCategory = createAsyncThunk('event/listByCategory', async ({ category, filter} : {category: string | undefined, filter: string | undefined}) => {
  const filterQuery = filter ? `/${filter}` : '';
  const response = await api.get(`events/category/${category}${filterQuery}`)
  
  return response.data
});

export const listNextEventByCategory = createAsyncThunk('event/listNextByCategory', async ({ category, filter, offset} : {category: string | undefined, filter: string | undefined, offset: number | undefined}) => {
  const filterQuery = filter ? `/${filter}` : '';
  const response = await api.get(`events/category/${category}${filterQuery}?offset=${offset}`)
  
  return response.data
});


export const adminListEventByCategory = createAsyncThunk('admin/event/listByCategory', async ({ category, filter} : {category: string | undefined, filter: string | undefined}) => {
  const filterQuery = filter ? `/${filter}` : '';
  const response = await api.get(`events/admin/category/${category}${filterQuery}`)
  
  return response.data
});



export const listSubscribedEvents = createAsyncThunk('event/listSubscribed', async () => {
  const response = await api.get(`events/me/subscribed`)
  return response.data
});


export const listEventsByCreator = createAsyncThunk('event/list', async (id: string) => {
  const response = await api.get(`events/user/${id}`)
  return response.data
});

export const getEvent = createAsyncThunk('event/get', async (id: string) => {
  const response = await api.get(`events/${id}`)
  return response.data
});


export const updateThumbnail = createAsyncThunk('event/updateThumbnail', async ({id, file} : {id: string, file: File}) => {
  const formData = new FormData();
  formData.append("file", file);
  return api.post(`events/${id}/thumbnail`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

});


const initialState = { 
  error: undefined,
  loading: false,
  events: [],
  event : undefined,
  categories: [],
  invoices: [],
  transferts: [],
  revenues: 0
 } as EventState

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    clearEvent(state){
      state.event = undefined;
      state.thumbnailUploaded = undefined;
    }
  },
  extraReducers(builder) {
    builder.addCase(createEvent.fulfilled, (state, action: PayloadAction<EventDTO>) => {
      state.event = action.payload;
    });
    builder.addCase(createEvent.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createEvent.rejected, (state, action) => {

      state.error = action.error.message;
    });

    builder.addCase(listHomePageEvents.pending, (state, action) => {
      state.loading = true;

    });
    builder.addCase(listHomePageEvents.fulfilled, (state, action) => {
      state.eventCount = action.payload.count;
      state.events = action.payload.results;
      state.eventNext = action.payload.next;
      state.loading = false;

    });
    builder.addCase(listEventsByCreator.fulfilled, (state, action) => {
      state.eventCount = action.payload.count;
      state.events = action.payload.results;
      state.eventNext = action.payload.next;
      state.loading = false;

    });
    builder.addCase(listEventsByCreator.rejected, (state, action) => {

    });
    builder.addCase(listEventsByCreator.pending, (state, action) => {
      state.loading = true;
      
    });
    builder.addCase(listMyEvents.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(listMyEvents.fulfilled, (state, action) => {
      state.eventCount = action.payload.count;
      state.events = action.payload.results;
      state.eventNext = action.payload.next;
      state.loading = false;
    });
    builder.addCase(getEvent.fulfilled, (state, action) => {
      state.event = action.payload;
    });
    builder.addCase(getEvent.pending, (state, action) => {
      state.loading = true;
      
    }); 
    builder.addCase(listAllEvents.fulfilled, (state, action) => {
      state.eventCount = action.payload.count;
      state.events = action.payload.results;
      state.eventNext = action.payload.next;
      
    });
    builder.addCase(listAllEvents.pending, (state, action) => {
      state.loading = true;
      
    });     
    builder.addCase(listSubscribedEvents.fulfilled, (state, action) => {
      state.eventCount = action.payload.count;
      state.events = action.payload.results;
      state.eventNext = action.payload.next;
      state.loading = false;

    });
    builder.addCase(listSubscribedEvents.pending, (state, action) => {
      state.loading = true;
      
    });         
    builder.addCase(listEventByCategory.fulfilled, (state, action) => {
      state.eventCount = action.payload.count;
      state.events = action.payload.results;
      state.eventNext = action.payload.next;
      state.loading = false;

    });
    builder.addCase(listNextEventByCategory.fulfilled, (state, action) => {
      state.eventCount = action.payload.count;
      state.eventNext = action.payload.next;
      state.events = [...state.events, ...action.payload.results];
      console.log(state.events);

    });    
    builder.addCase(listEventByCategory.pending, (state, action) => {
      state.loading = true;
      
    });         
    builder.addCase(updateThumbnail.fulfilled, (state, action) => {
      state.thumbnailUploaded = true;
    });
    builder.addCase(listCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    })
    builder.addCase(listInvoices.fulfilled, (state, action) => {
      state.invoices = action.payload;
    })

    builder.addCase(listMyTransferts.fulfilled, (state, action) => {
      state.transferts = action.payload.transferts;
      state.revenues = action.payload.revenue;
    })


    builder.addCase(adminListEventByCategory.fulfilled, (state, action) => {
      state.events = action.payload;
      
    })


    builder.addCase(adminListAllEvents.fulfilled, (state, action) => {
      state.events = action.payload;
    })


    

  }
})

export const { clearEvent } = eventSlice.actions
export default eventSlice.reducer