import { Col } from "reactstrap";
import { EventDTO, EventPaymentType, EventState } from "../dto/event.dto";
import BASE_URL from "../../../common/urls";
import moment from "moment";
import "./event-image.css"
import useGeoLocation from "react-ipgeolocation";
import { getPriceByLocation } from "../../../common/price";
import { useEffect, useState } from "react";
import { t } from "i18next";

interface EventImageProps{
    title: string;
    image? : string;
    isPreview?: boolean;
    isLive ?: boolean;
    showMeta?: boolean;
    event?: EventDTO ;
    onSwitchColor?: Function;
    color?: string;
}

export default function EventImage(props: EventImageProps){
    const location = useGeoLocation();
    const [priceLive, setPriceLive] = useState<number | undefined>();
    useEffect(() => {
        if (location.country != undefined && props.event ){
            setPriceLive(getPriceByLocation(props.event, location.country));
        }
    }, [location]);


    return <div className="event-image">
        { props.image &&  <img className="image" src={!props.isPreview ? props.image : props.image } />} 
        { !props.image && <Col className="no-image-container" style={{backgroundColor: props.color}}>
            {props.isPreview && <div className="color-switcher" onClick={() => { props.onSwitchColor && props.onSwitchColor()}}><img src={require("../../../assets/switch-color.png")} style={{objectFit: "contain", maxWidth: 20}} /></div>}

            <h4 style={{fontSize: "1em", textTransform: "uppercase", fontWeight: "bold"}}>{ props.title }</h4>
        </Col> }
        {props.event != undefined  && <div className="event-overlay-container">
            {props.event.state == EventState.Live ? <div className="widget live-widget">live</div>: props.event.state == EventState.Draft ? <div className="widget date-widget"><div className="date">{`${moment(props.event.date).format("DD")} `}</div><div className="date"><span className="slash">/</span>{moment(props.event.date).format("MM")}</div><div className="time">{moment(props.event.date).format("hh")}:{moment(props.event.date).format("mm")}</div></div>:<div className="widget date-widget">{  moment.utc(moment(props.event.liveEndedAt).diff(props.event.liveStartedAt, "seconds") * 1000).format("HH:mm:ss") }</div>}
            <div className="widget price">
            { props.event.state == EventState.Draft || props.event.state == EventState.Live ? priceLive : props.event.priceVOD } $ {props.event.state != EventState.Vod && props.event.paymentType == EventPaymentType.Time && "/ minute"}
            </div>
            {props.event.seats && props.event.state !== EventState.Vod && <div className="widget seats-limited">
                { props.event.isSubscribed ?  t("event.already_subscribed") : props.event.isFull == true ? "Sold out" : t("event.seats_limited") } 
            </div> }            
            {props.event.state === EventState.Vod && props.event.isSubscribed && <div className="widget seats-limited">
                
                { t("event.already_subscribed_vod")  } 
            </div> }                        
        </div>}
    </div>
}