import { Button, Col, Input, Label, Row } from "reactstrap"
import { EventDTO, EventPaymentType, EventState } from "../dto/event.dto"
import moment from "moment"
import EventImage from "./event-image"
import { Link, NavLink } from "react-router-dom"
import { useEffect, useState } from "react"
import { useAppSelector } from "../../../hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import ButtonCTAEvent from "./button-cta-event"
import { Trans } from "react-i18next"

interface EventHeaderProps {
    event : EventDTO,
    editable?: boolean,
    onEditMode?: Function
    
}

export default function EventHeader(props: EventHeaderProps){
    const config = useAppSelector((state) => state.app.config);
    const user = useAppSelector((state) => state.login.user);
    const [studioReady, setStudioReady] = useState(false);

    const checkStudioReady = () => {
        const studioReady = moment(props.event?.date).subtract(config?.studioAvailableBefore || 10, 'minutes').isBefore(moment());
        setStudioReady(studioReady);
    }

    useEffect(() => {
        let intervalRef : NodeJS.Timer | undefined;
        if (props.event && config){
            checkStudioReady();
            console.log("studio ready ?" + props.event.date + " " + studioReady); 
            intervalRef = setInterval( ( ) => {
                checkStudioReady();

            }, 1000)
        }

        return () => {
            clearInterval(intervalRef);
        }

    }, [props.event, config]);

    return <>{ props.event && <Row style={{marginBottom: 40}}>
    <Col md={6}>
        <EventImage title={props.event.title} image={props.event.picture} isLive={props.event.state == EventState.Live} event={props.event} color={props.event.color}/>
        {props.editable && <>{ props.event.isOwner && <Row style={{marginTop: 30, marginLeft: 0, marginRight: 0}}>
                <Label for="url" style={{marginLeft: 0}}>
                    Lien à partager
                </Label>                   
                <Input
                        bsSize="lg"
                        className="mb-3"
                        placeholder="lg"
                        readOnly={true}
                        value={`https://app.weerlive.com/event/${props.event._id}`}
                    />
                </Row>}
                <Row style={{textAlign: 'left', marginLeft: 0, marginRight:0}}>
                    {props.event.isOwner && props.event.state === EventState.Vod && <NavLink to={`/vod/${props.event._id}`} style={{margin: 0, padding:0 }}><Button color="green" style={{width: '100%', marginBottom: '1rem'}}><Trans i18nKey={"event.header.edit"}>Voir la VOD</Trans></Button></NavLink>}
                    {props.event.isOwner && props.event.state !== EventState.Live && <NavLink to={`/event/${props.event._id}/edit`} style={{margin: 0, padding:0 }}><Button color="primary" style={{width: '100%'}}><Trans i18nKey={"event.header.edit"}>Éditer</Trans></Button></NavLink>}
                    { props.event.isOwner && props.event.state !== EventState.Live && studioReady && <Link style={{padding: 0}} to={`/studio/${props.event._id}`} target="_blank"><Button color="green" style={{width: '100%', marginTop: '1rem'}}><FontAwesomeIcon style={{marginRight: 10}} icon={faPlay} /> <Trans i18nKey={"event.header.start_live"}>Démarrer le direct</Trans></Button></Link> } 
                    { props.event.isOwner && props.event.state === EventState.Live && studioReady && <Link style={{padding: 0}} to={`/studio/${props.event._id}`} target="_blank"><Button color="green" style={{width: '100%', marginTop: '1rem'}}><FontAwesomeIcon style={{marginRight: 10}} icon={faPlay} /> <Trans i18nKey={"event.header.join_live"}>Rejoindre le studio</Trans></Button></Link> } 
                    {! props.event.isOwner && <ButtonCTAEvent user={user} event={props.event} /> }
                </Row></>}
    </Col>
    <Col md={6}>
        <h1>{props.event.title}</h1>
        <p>{props.event.description}</p>
        
    </Col>
</Row>}</>
}

